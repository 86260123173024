import { FormControl, FormControlLabel,  FormLabel, Radio, RadioGroup} from "@mui/material";
import AutoCompleteGroups from "../../../Components/AutoCompleteGroups"
import AutoCompleteStudents from "../../../Components/AutoCompleteStudents"
import { ClassContext } from "../../../Context/ClassContext";
import { useContext } from "react";
import { UserContext } from "../../../Context/UserContext";
import { useTranslation } from "react-i18next";

export default function AssignmentComp({classID}) {
    const { form, setForm } = useContext(ClassContext)
    const { user } = useContext(UserContext)
    const {t} = useTranslation()

    return <>
        <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">{t("clase.nueva.asignar")} <span className="text-danger">(required)</span>:</FormLabel>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                value={form.asignadoA}
                onChange={ev=>setForm(form=>({...form, asignadoA: ev.target.value}))}
            >
                <FormControlLabel value="grupo" disabled={classID ? true:false} control={<Radio />} label={t("clase.nueva.grupo")} />
                <FormControlLabel value="alumno" disabled={classID ? true:false} control={<Radio />} label={t("clase.nueva.alumno")} />
            </RadioGroup>
        </FormControl>
        { form.asignadoA ? form.asignadoA === "grupo" ? <div class="mb-3">
            <label class="form-label">{t("clase.nueva.placeGrupo")}:</label>
            <AutoCompleteGroups disabled={classID ? true:false} user={user} value={form.grupoSeleccionado} label={t("clase.nueva.placeGrupo")}
                    onChange={val=>setForm(form=>({...form, grupoSeleccionado: val}))}/>
        </div> : <div class="m b-3">
            <label class="form-label">{t("clase.nueva.placeAlum")}:</label>
            <AutoCompleteStudents disabled={classID ? true:false} user={user} value={form.alumnoSeleccionado}  label={t("clase.nueva.placeAlum")}
                    onChange={val=>setForm(form=>({...form, alumnoSeleccionado: val}))}/>
        </div> : null}
    </>
}