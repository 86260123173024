import { useContext } from "react";
import { FormControlLabel,  Checkbox, FormGroup} from "@mui/material";
import { ClassContext } from "../../../Context/ClassContext";
import { useTranslation } from "react-i18next";

export default function Reminders () {
    const { form, setForm } = useContext(ClassContext)
    const {t} = useTranslation()
    
    return <div>
        <FormControlLabel control={
                <Checkbox 
                    checked={form.mandarRecordatorios}
                    onChange={ev=>setForm(form=>({...form, mandarRecordatorios: ev.target.checked }))}/>
            } label={t("clase.nueva.recordatorios")} />
        {form.mandarRecordatorios && <fieldset class="mb-3">
            
            <div class="row">
                <label class="col-form-label col-sm-3 pt-0">{t("clase.nueva.recorda.descrip")}:</label>
                <FormGroup>
                    <FormControlLabel control={
                        <Checkbox 
                            checked={form.recordatorios.quinceminutos}
                            onChange={ev=>setForm(form=>({...form, recordatorios: {...form.recordatorios, quinceminutos: ev.target.checked }}))}/>
                    } label={t("clase.nueva.recorda.quince")} />
                    <FormControlLabel control={
                        <Checkbox 
                            checked={form.recordatorios.unahora}
                            onChange={ev=>setForm(form=>({...form, recordatorios: {...form.recordatorios, unahora: ev.target.checked }}))}/>
                    } label={t("clase.nueva.recorda.hora")} />
                    <FormControlLabel control={
                        <Checkbox 
                            checked={form.recordatorios.undia}
                            onChange={ev=>setForm(form=>({...form, recordatorios: {...form.recordatorios, undia: ev.target.checked }}))}/>
                    } label={t("clase.nueva.recorda.dia")} />
                </FormGroup>
            </div>
        </fieldset>}
    </div>
}