import React, { useEffect, useState } from "react"
import firebase from "firebase"
import Axios from "axios"
import Constants from "../Utilities/Constants"
require("firebase/auth")

const UserContext = React.createContext()
const { Provider, Consumer } = UserContext

const UserProvider = ({ children }) => {
    const [user, setUser] = useState({ package: {} })

    const [isAnUserAuthenticated, setIsAnUserAuthenticated] = useState(undefined)

    const updateUserData = async (userID) => {
        let snapshot = await firebase
            .database()
            .ref("users/" + userID)
            .once("value")
        const userData = snapshot.val()
        snapshot = await firebase
            .database()
            .ref("packages/" + userData.packageID)
            .once("value")
        const packageData = snapshot.val()
        setUser({ userID, ...userData, package: packageData })
    }

    useEffect(() => {
        firebase.auth().onAuthStateChanged((authUser) => {
            console.log("el usuario es ", authUser)
            if (authUser) {
                firebase.auth().currentUser.getIdTokenResult().then(metadata=>{
                    console.log("la resp de metadata", metadata)
                    setUser(metadata.claims)
                    setIsAnUserAuthenticated(true)
                    console.log("logged")
                })
                // firebase.database().ref("users/" + authUser.uid).on("value", (snapshot) => {
                //     const userInfo = snapshot.val()  
                //     if (userInfo) updateUserData(authUser.uid)
                // })
                //updateUserData(authUser.uid)
                
            } else {
                setUser({ package: {} })
                setIsAnUserAuthenticated(false)
                console.log("NOT logged")
            }
        })
    }, [])

    const signUpUser = async (email, password, name, type) => {
        try {
            const response = await Axios.post(`${Constants.functionsURL()}/signUpUser`, { email, password, name, type })
            if (response.data.success === 1) {
                return true
            }
            console.log("Error on signUpUser: ", response.data.error)
            return false
        } catch (error) {
            console.log("Error on signUpUser: ", error)
            return false
        }
    }

    const signUpReferedUser = async (email, password, name, referringUser) => {
        try {
            const response = await Axios.post(
                "https://us-central1-criptoestrategias-ef832.cloudfunctions.net/signUpReferedUser",
                {
                    email,
                    password,
                    name,
                    referringUser,
                }
            )
            if (response.data.success === 1) {
                return true
            }
            console.log("Error on signUpReferedUser: ", response.data.error)
            return false
        } catch (error) {
            console.log("Error on signUpReferedUser: ", error)
            return false
        }
    }

    const setTelegramID = async (telegramID) => {
        try {
            const response = await Axios.post(
                "https://us-central1-criptoestrategias-ef832.cloudfunctions.net/setTelegramID",
                {
                    userID: user.userID,
                    telegramID,
                }
            )
            if (response.data.success === 1) {
                return true
            }
            console.log("Error on setTelegramID: ", response.data.error)
            return false
        } catch (error) {
            console.log("Error on setTelegramID: ", error)
            return false
        }
    }

    const updateUserInfo = async (name, telegramID) => {
        try {
            const response = await Axios.post(
                "https://us-central1-criptoestrategias-ef832.cloudfunctions.net/updateUserInfo",
                {
                    userID: user.userID,
                    name,
                    telegramID,
                }
            )
            if (response.data.success === 1) {
                return true
            }
            console.log("Error on updateUserInfo: ", response.data.error)
            return false
        } catch (error) {
            console.log("Error on updateUserInfo: ", error)
            return false
        }
    }

    const signInUser = async (email, password) => {
        try {
            const response = await firebase.auth().signInWithEmailAndPassword(email, password)
            if (!response.user) return false
            return true
        } catch (error) {
            console.log("Error on signInUser")
            console.log("Error code: ", error.code)
            console.log("Error message: ", error.message)
            if (error.code === "auth/user-not-found" || error.code === "auth/wrong-password") {
                return { message: "Invalid user or password" }
            }
            return false
        }
    }

    const signOutUser = async () => {
        try {
            setIsAnUserAuthenticated(false)
            await firebase.auth().signOut()
            console.log("users/" + user.userID)
            return true
        } catch (error) {
            console.log("Error on signOutUser")
            console.log("Error code: ", error.code)
            console.log("Error message: ", error.message)
            return false
        }
    }

    const makePackageRequest = async (packageID) => {
        try {
            const response = await Axios.post(
                "https://us-central1-criptoestrategias-ef832.cloudfunctions.net/makePackageRequest",
                {
                    userID: user.userID,
                    packageID,
                }
            )
            if (response.data.success === 1) {
                updateUserData(user.userID)
                return true
            }
            console.log("Error on makePackageRequest: ", response.data.error)
            return false
        } catch (error) {
            console.log("Error on makePackageRequest: ", error)
            return false
        }
    }

    const toggleStrategy = async (strategyID) => {
        try {
            const response = await Axios.post(
                "https://us-central1-criptoestrategias-ef832.cloudfunctions.net/toggleSelectedStrategy",
                {
                    userID: user.userID,
                    strategyID,
                }
            )
            if (response.data.success === 1) {
                updateUserData(user.userID)
                return true
            }
            console.log("Error on toggleStrategy: ", response.data.error)
            return false
        } catch (error) {
            console.log("Error on toggleStrategy: ", error)
            return false
        }
    }

    const aprovePackageRequestFromUser = async (invoiceUserID) => {
        try {
            const response = await Axios.post(
                "https://us-central1-criptoestrategias-ef832.cloudfunctions.net/aprovePackageRequestFromUser",
                {
                    userID: invoiceUserID,
                }
            )
            if (response.data.success === 1) {
                return true
            }
            console.log("Error on aprovePackageRequestFromUser: ", response.data.error)
            return false
        } catch (error) {
            console.log("Error on aprovePackageRequestFromUser: ", error)
            return false
        }
    }

    const cancelPackageRequestFromUser = async () => {
        try {
            const response = await Axios.post(
                "https://us-central1-criptoestrategias-ef832.cloudfunctions.net/cancelPackageRequestFromUser",
                {
                    userID: user.userID,
                }
            )
            if (response.data.success === 1) {
                return true
            }
            console.log("Error on cancelPackageRequestFromUser: ", response.data.error)
            return false
        } catch (error) {
            console.log("Error on cancelPackageRequestFromUser: ", error)
            return false
        }
    }

    const aprovePaymentFromUser = async (paymentID) => {
        try {
            const response = await Axios.post(
                "https://us-central1-criptoestrategias-ef832.cloudfunctions.net/aprovePaymentFromUser",
                {
                    userID: user.userID,
                    paymentID,
                }
            )
            if (response.data.success === 1) {
                return true
            }
            console.log("Error on aprovePaymentFromUser: ", response.data.error)
            return false
        } catch (error) {
            console.log("Error on aprovePaymentFromUser: ", error)
            return false
        }
    }

    const getLeftDays = () => {
        return (
            30 -
            (Math.ceil(Math.abs(Date.now() - new Date(user.lastUpdate || user.createdAt)) / (1000 * 60 * 60 * 24)) - 1)
        )
    }

    return (
        <Provider
            value={{
                user,
                signInUser,
                signUpUser,
                signUpReferedUser,
                isAnUserAuthenticated,
                makePackageRequest,
                toggleStrategy,
                getLeftDays,
                signOutUser,
                setTelegramID,
                updateUserInfo,
                aprovePackageRequestFromUser,
                cancelPackageRequestFromUser,
                aprovePaymentFromUser,
            }}
        >
            {children}
        </Provider>
    )
}
export { UserProvider, Consumer as UserConsumer, UserContext }
