const Constants = {
    env: "dev",
    functionsURL: () => {
        if(window.location.host === "localhost:3000") return "http://127.0.0.1:5001/lapape-83eb0/us-central1"
        else return "https://us-central1-lapape-83eb0.cloudfunctions.net"
    },
    restURL: () => {
        // if(window.location.host === "localhost:3000") return "http://localhost/api_skoolar/"
        if(false) return "http://localhost/api_skoolar/"
        else return "https://api.skoolar.app/"
    }
}

export default Constants