import { useContext, useEffect, useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import Breadcrumb from "../../panelLayout/Breadcrumb";
import PanelLayout from "../../panelLayout/Layout";
import firebase from "firebase"
import Axios from "axios"
import { UserContext } from "../../Context/UserContext";
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { LayoutContext } from "../../Context/LayoutContext";
import i18n from "../../Utilities/Multilenguaje";
import Constants from "../../Utilities/Constants";
import Loading from "../../panelLayout/Loading/Loading";
require("firebase/database")


export default function Horario() {
    const {t} = useTranslation()
    const { addToast } = useToasts();
    const { setTitle } = useContext(LayoutContext)
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)
    const location = useLocation()
    const { user } = useContext(UserContext)
    const history = useHistory();

    const convertToRFC3339 = (date) => {
        const year = date.getUTCFullYear();
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const day = date.getUTCDate().toString().padStart(2, '0');
        const hours = date.getUTCHours().toString().padStart(2, '0');
        const minutes = date.getUTCMinutes().toString().padStart(2, '0');
        const seconds = date.getUTCSeconds().toString().padStart(2, '0');
      
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
    }

    const getGoogleEvents = (e) => {
        // console.log("get google events")
        const startDate = convertToRFC3339(e.view.currentStart)
        const endDate = convertToRFC3339(e.view.currentEnd)
        Axios.post(`${Constants.functionsURL()}/getEvents`, {startDate, endDate, userEmail: user.email}).then(response=>{
     
            // console.log("la resp", response.data)
            if (response.data.success === 1) {
                let aux = response.data.events.map(clase=> {
                    const startTime = new Date(clase.start.dateTime)
                    const endTime = new Date(clase.end.dateTime)
                    return ({ title: clase.summary, allDay: false,start: startTime, end: endTime, original: clase, claseID: ( clase.recurringEventId ? clase.recurringEventId : clase.id )})
                } )
                console.log("la aux es ", aux)
                setData(aux)
                
            }
        })
        
    }

    useEffect(()=>setTitle(t("horario.titulo")), [])

    return <div className="content-body">
        <div className="container-fluid">
            <Breadcrumb pageTitles={[{label: t("migajas.inicio"), to: "/"}, t("horario.titulo")]} />
            
            <div className="row">
                {user && user.type === "Teacher" && <div className="col-12">
                    <div class="form-head d-flex mb-3 mb-md-5 align-items-center  flex-row-reverse">
                        <NavLink class="ms-auto rounded-0 btn bgl-primary text-primary" to="/panel/classes/new" component="a">
                            {t("clase.lista.boton")}
                        </NavLink>
                    </div>
                </div>} 
                <div className="col-12">
                    { user.user_id ? <FullCalendar
                        plugins={[ dayGridPlugin ]}
                        initialView="dayGridMonth"
                        locale={i18n.language.includes("es") ? "es" : "en"}
                        events={data}
                        eventClick={(eventInfo=>{
                            // console.log("la info2 del evento", eventInfo)
                            // const {claseID} = eventInfo.event._def.extendedProps
                            const claseID = eventInfo.event._def.extendedProps.original.id
                            // console.log("la claseID del evento", eventInfo.event._def.extendedProps)
                            history.push(`/panel/classes/detail/${claseID}`)
                        })}
                        datesSet={getGoogleEvents}
                        navLinks={true}
                        headerToolbar={{
                            left: 'prev,next',
                            center: 'title',
                            right: 'dayGridMonth,dayGridWeek,dayGridDay' // user can switch between the two
                          }}
                        eventContent={renderEventContent}
                    /> : 
                    <div className="capa">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>}
                </div>
            </div>
        </div>    
    </div>
}

function renderEventContent(eventInfo) {
    //we set the dates
    const {end, start} = eventInfo.event._instance.range
    const startDate = new Date(start.getTime())
    startDate.setTime( startDate.getTime() + startDate.getTimezoneOffset()*60*1000 );
    const endDate = new Date(end.getTime())
    endDate.setTime( endDate.getTime() + endDate.getTimezoneOffset()*60*1000 );

    const original = eventInfo.event._def.extendedProps.original
    //console.log("la original del evento", original)
    const isPast = new Date() > startDate
    return (
      <div style={{ color: "white", width: "100%", boxSizing: "border-box", padding: 10, backgroundColor: isPast ? "rgba(66,66,66)" : "rgb(26,141,52)", textTransform: "capitalize"}}>
        <b>
            {eventInfo.event._def.title}<br/>
            {formatAMPM(startDate)} - {formatAMPM(endDate)}<br/>
        </b>
      </div>
    )
}

function formatAMPM(sendedDate) {
    var hours = sendedDate.getHours();
    var minutes = sendedDate.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }