export default function ActividadDetalleEvento ({evento}) {
    const setSumatoria = ()=>{
        let sumatoria = 0
        evento.actividad?.materiales?.map(material=>sumatoria+=parseInt(material.cantidad))
        return sumatoria
    }
    return <div class="card">
        <div class="card-header border-0 pb-0">
            <h2 class="card-title">Actividad: {evento.actividad?.nombre}</h2>
        </div>
        <div class="card-body pb-0">
            <p>{evento.actividad?.descripcion}</p>
        </div>
        <div class="card-footer pt-0 pb-0 text-center">
            <div class="row">
                <div class="col-6 pt-3 pb-3 border-right">
                    <h3 class="mb-1 text-primary">{evento.actividad?.materiales?.length}</h3>
                    <span>Materiales</span>
                </div>
                <div class="col-6 pt-3 pb-3">
                    <h3 class="mb-1 text-primary">{setSumatoria()}</h3>
                    <span>Artículos</span>
                </div>
            </div>
        </div>
    </div>
}