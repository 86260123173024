import { useContext, useEffect, useState } from "react";
import { useParams, NavLink } from "react-router-dom";
import firebase from "firebase"
import { UserContext } from "../../Context/UserContext";
import Breadcrumb from "../../panelLayout/Breadcrumb";
import { LayoutContext } from "../../Context/LayoutContext";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
require("firebase/database")

export default function DetailGroups(){
    const {t} = useTranslation()
    const { setTitle } = useContext(LayoutContext)
    const history = useHistory();
    const { addToast } = useToasts();
    const { groupID } = useParams();
    const { user } = useContext(UserContext)
    const [groupData, setGroupData] = useState({})
    const [students, setStudents] = useState([])

    useEffect(()=>{
        console.log("checo el params", groupID)
        if(groupID && user && user.user_id){
            firebase.database().ref("grupos").child(user.user_id).child(groupID).once("value", snap=>{
                const auxGroup = snap.val()
                setGroupData(auxGroup)
                if(auxGroup.students && Array.isArray(auxGroup.students) && auxGroup.students.length > 0){
                    firebase.database().ref("alumnos").child(user.user_id).on("value", snap=>{
                        let auxStudents = snap.val()
                        console.log("traigo todos los estu", auxStudents)
                        let groupStudents = []
                        auxGroup.students.map(selectedID=>{
                            groupStudents.push(auxStudents[selectedID])
                        })
                        console.log("quedan", groupStudents)
                        setStudents(groupStudents)
                    })
                }
            })
        }
    }, [groupID, user])
    useEffect(()=>setTitle(t("grupos.detalle.titulo")), [])

    const borrarGrupo = (grupoID, grupoNombre) => {
        const response = window.confirm(`${t("grupos.lista.borrar")} ${grupoNombre}?`)
        if(response){ 
            firebase.database().ref("grupos").child(user.user_id).child(grupoID).set(null).then(()=>{
                addToast("The Classroom has been deleted successfully", { appearance: 'success', autoDismiss: true });
                history.push(`/panel/groups/list`)
            })
        }
    }

    return <div className="content-body">
        <div className="container-fluid">
            <Breadcrumb pageTitles={[{label: t("migajas.inicio"), to: "/"}, {label: t("grupos.detalle.bread"), to: "/panel/groups/list"}, t("grupos.detalle.titulo")]} />
            
            <div className="row">
                <div className="col-md-12">
                    <div class="form-head d-flex mb-4 align-items-center  flex-row-reverse">
                        <button class="ms-auto ml-3 rounded-0 btn btn-sm btn-outline-danger" onClick={() => borrarGrupo(groupID, groupData.nombre)} >
                            {t("grupos.lista.drop.borrar")}
                        </button>
                        <NavLink class="ms-auto ml-3 rounded-0 btn btn-sm btn-outline-primary" to={`/panel/groups/edit/${groupID}`} component="a">
                            {t("grupos.lista.drop.generales")}
                        </NavLink>
                        <NavLink class="ms-auto ml-3 rounded-0 btn btn-sm btn-outline-primary" to={`/panel/groups/select-students/${groupID}`} component="a">
                            {t("grupos.lista.drop.editar")}
                        </NavLink>
                        
                    </div>
                </div>
                <div className="col-xl-6 col-xxl-12">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <div className="card mb-xl-0 h-auto">
                                
                                <div className="card-body">
                                    <div class="row">
                                        <div class="col-sm-5 mb-4">
                                            <p class="mb-2">Classroom Name</p>
                                            <h4 class="text-black text-uppercase">{groupData.nombre}</h4>
                                        </div>
                                        <div class="col-sm-7">
                                            <p class="mb-2">School</p>
                                            <h4 class="text-black text-uppercase">{groupData.escuela ? groupData.escuela : "-"}</h4>
                                        </div>
                                        <div class="col-sm-5">
                                            <p class="mb-2">Number of Students</p>
                                            <h4 class="text-black">{students && Array.isArray(students) ? students.length : 0}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-xxl-12">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <div className="card">
                                <div className="card-header border-0 pb-0 d-sm-flex d-block">
                                    <div>
                                        <h4 className="text-black fs-20">{t("grupos.detalle.estudiantes")}</h4>
                                        <p className="fs-12 mb-0">{t("grupos.detalle.descripestudiantes")}</p>
                                        
                                    </div>
                                </div>
                                <div className="card-body contacts-list">
                                    {students && Array.isArray(students) && students.length > 0 && students.map(student =><div className="media mb-2 align-items-center">
                                        <img className="me-3 rounded-0 width70 height70 mr-3"  src="/images/male.jpg" alt=""/>
                                        <div className="media-body">
                                            <h6 className="text-black fs-18 mb-0">{student.nombre}</h6>
                                            <span className="fs-14">{student.correo}</span>
                                        </div>
                                        
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
    </div>
}