export default function MaterialesDetalleEvento({evento, user, changePrice, eventoID}) {
    
    return <div class="card" style={{height: "auto"}}>
        <div class="card-header">
            <h4 class="card-title">Materials to Use</h4>
        </div>
        <div class="card-body">
            <div class="table-responsive recentOrderTable">
                <table class="table verticle-middle ">
                    <thead>
                        <tr>
                            <th scope="col">Material</th>
                            <th scope="col">Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {evento.materiales?.map((material, indice)=><tr>
                            <td>{material.nombre}</td>
                            <td>{material.cantidad}</td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}