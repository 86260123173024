export default function EventLinks({evento, user, changePrice, eventoID}) {
    
    return <div class="card" style={{height: "auto"}}>
        <div class="card-header">
            <h4 class="card-title">Related Links</h4>
        </div>
        <div class="card-body">
            <ul>
                {evento.links?.map((material, indice)=><li>
                    {"->"} <a href={material.url} target="_blank" rel="noreferrer">{material.label}</a>
                </li>)}
            </ul>
        </div>
    </div>
}