import { useContext, useEffect, useState} from "react";
import { NavLink } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
import Breadcrumb from "../../panelLayout/Breadcrumb";
import PanelLayout from "../../panelLayout/Layout";
import firebase from "firebase"
import { UserContext } from "../../Context/UserContext";
import { useTranslation } from "react-i18next";
import { LayoutContext } from "../../Context/LayoutContext";
import { useToasts } from "react-toast-notifications";
require("firebase/database")

export default function SelectStudents() {
    const { groupID } = useParams();
    const {t} = useTranslation()
    const { addToast } = useToasts();
    const { setTitle } = useContext(LayoutContext)
    const history = useHistory();
    const [data, setData] = useState({})
    const [selected, setSelected] = useState([])
    const { user } = useContext(UserContext)

    const saveGroup = () => {
        console.log("el seleccioado", selected)
        firebase.database().ref("grupos").child(user.user_id).child(groupID).update({
            students: selected
        }).then(redirectTo)
    }
    const redirectTo = () => {
        addToast(t("grupos.select.mensaje"), { appearance: 'success', autoDismiss: true });
        history.push("/panel/groups/list")
    }
    useEffect(()=>{
        if(user && user.user_id){
            firebase.database().ref("alumnos").child(user.user_id).on("value", snap=>{
                const alumnos = snap.val()
                if(alumnos) setData(alumnos)
                else setData({})                        
            })
            firebase.database().ref("grupos").child(user.user_id).child(groupID).child("students").on("value", snap=>{
                const students = snap.val()
                if(students && Array.isArray(students) && students.length > 0){
                    console.log("los estudiantes son", students)
                    setSelected(students)
                }
            })
        }
    }, [user])
    useEffect(()=>setTitle(t("grupos.select.titulo")), [])

    return <div className="content-body">
        <div className="container-fluid">
            <Breadcrumb pageTitles={[{label: t("migajas.inicio"), to: "/"}, {label: t("grupos.select.bread"), to: "/panel/groups/list"}, t("grupos.select.titulo")]} />
            
            <div className="row">
                <div className="col-12">
                    
                    <div className="table-responsive table-hover fs-14">
                        <table className="table display mb-4 dataTablesCard  card-table table-stripped">
                            <thead>
                                <tr>
                                    <th>{t("grupos.select.tabla.selec")}</th>
                                    <th>{t("grupos.select.tabla.nom")}</th>
                                </tr>
                            </thead>
                            <tbody> 
                                {
                                    Object.keys(data).map((char)=>{
                                        const grupo = data[char]
                                        return <tr>
                                            <td><input type={"checkbox"} 
                                                checked={selected && Array.isArray(selected) && selected.includes(char) ? true : false} 
                                                onChange={ev=>{
                                                if(ev.target.checked) setSelected(selected=>([...selected, char]))
                                                    else setSelected(selected=> selected.filter((val)=>(val != char)))
                                                }} 
                                                /></td>
                                            <td>{grupo.nombre}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div class="form-head d-flex mb-3 mb-md-5 align-items-center  flex-row-reverse">
                        <button class="ms-auto rounded-0 btn bgl-primary text-primary" onClick={saveGroup}>
                            {t("grupos.select.boton")}
                        </button>
                    </div>
                </div>
            </div>
        </div>    
    </div>
}