import { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Breadcrumb from "../../panelLayout/Breadcrumb";
import PanelLayout from "../../panelLayout/Layout";
import firebase from "firebase"
import { UserContext } from "../../Context/UserContext";
require("firebase/database")

export default function Eventos() {
    const [data, setData] = useState({})
    const { user } = useContext(UserContext)
    const borrarGrupo = (grupoID, grupoNombre) => {
        const response = window.confirm(`¿Está seguro que desea eliminar el grupo ${grupoNombre}?`)
        if(response) firebase.database().ref("grupos").child(user.escuelaID).child(grupoID).set(null)
    }
    useEffect(()=>{
        if(!user.escuelaID) return false
        firebase.database().ref("eventos").child(user.escuelaID).child(user.user_id).on("value", snap=>{
            const grupos = snap.val()
            console.log("los grupos son", grupos)
            if(grupos) setData(grupos)
            else setData({})                        
        })
    }, [user])
    return <PanelLayout title="Gestión de Eventos">
    <div className="content-body">
        <div className="container-fluid">
            <Breadcrumb pageTitles={["Home", "Gestión de Eventos"]} />
            
            <div className="row">
                <div className="col-12">
                    <div class="form-head d-flex mb-3 mb-md-5 align-items-center  flex-row-reverse">
                        <NavLink class="ms-auto rounded-0 btn bgl-primary text-primary" to="/nuevo-evento" component="a">
                            Nuevo evento
                        </NavLink>
                    </div>
                    <div className="table-responsive table-hover fs-14">
                        <table className="table display mb-4 dataTablesCard  card-table table-stripped">
                            <thead>
                                <tr>
                                    <th>Grupo</th>
                                    <th>Actividad</th>
                                    <th>Fecha</th>
                                    <th>Estado</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody> 
                                {
                                    Object.keys(data).map(char=>{
                                        const grupo = data[char]
                                        return <tr>
                                            <td>{grupo.grupo?.nombre}</td>
                                            <td>{grupo.actividad?.nombre}</td>
                                            <td>{grupo.fecha}</td>
                                            <td>{grupo.estado}</td>
                                            <td>
                                                <div class="d-flex">
                                                    <NavLink to={`/detalle-evento/${char}`} class="btn btn-primary shadow btn-xs sharp me-1"><i class="fa fa-eye"></i></NavLink>
                                                </div>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>    
    </div>
    </PanelLayout>
}