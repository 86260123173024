import { Autocomplete, TextField } from "@mui/material";
import firebase from "firebase"
import { useEffect, useState } from "react";
require("firebase/database")

export default function AutoCompleteGroups({user, onChange, value, label, disabled}) {
    const [data, setData] = useState([])
    useEffect(()=>{
        console.log("entro")
        if(!user.user_id) return false
        console.log("wiii", user.user_id)
        firebase.database().ref("grupos").child(user.user_id).once("value", snap=>{
            console.log("snap")
            const grupos = snap.val()
            console.log("los grupos son", grupos)
            if(grupos) {
                let aux =[]
                const ids = Object.keys(grupos)
                ids.map(id=>aux.push({
                    id, label: grupos[id].nombre
                }))
                setData(aux)
            }                       
        })
    }, [user])

    return <Autocomplete
        disablePortal
        disabled={disabled}
        value={value?.label}
        onChange={(val, reason)=> onChange(reason) }
        options={data}
        sx={{ width: "100%" }}
        renderInput={(params) => <TextField {...params} label={label} />}
    />
}