import { useContext, useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Breadcrumb from "../../panelLayout/Breadcrumb";
import PanelLayout from "../../panelLayout/Layout";
import firebase from "firebase"
import { UserContext } from "../../Context/UserContext";

//componentes
import GrupoDetalleEvento from "./grupo"
import ActividadDetalleEvento from "./actividad"
import AlumnosDetalleEvento from "./alumnos"
import MaterialesDetalleEvento from "./materiales"
require("firebase/database")

export default function DetalleEvento() {
    const { eventoID } = useParams()
    const [data, setData] = useState({})
    const { user } = useContext(UserContext)

    useEffect(()=>{
        if(!user.escuelaID) return false
        firebase.database().ref("eventos").child(user.escuelaID).child(user.user_id).child(eventoID).on("value", snap=>{
            const grupos = snap.val()
            if(grupos) setData(grupos)
            else setData({})                        
        })
    }, [user])
    const changePrice = (ev, indice) => {
        let materialesAux = [...data.actividad.materiales]
        materialesAux[indice].precio = ev.target.value
        materialesAux[indice].subtotal = materialesAux[indice].precio > 0 ? materialesAux[indice].cantidad * materialesAux[indice].precio : 0
        setData(data=>({...data, actividad: {...data.actividad, materiales: materialesAux}})) 
    }
    
    
    return <PanelLayout title="Detalles del evento">
    <div className="content-body">
        <div className="container-fluid">
            <Breadcrumb pageTitles={["Home", "Detalles del evento"]} />
            
            <div className="row">
                <div className="col-7">
                    <div className="row">
                        <div className="col-6">
                            <GrupoDetalleEvento evento={data} />
                        </div>
                        <div className="col-6">
                            <ActividadDetalleEvento evento={data} />
                        </div>
                        <div className="col-12">
                            <AlumnosDetalleEvento evento={data} />
                        </div>
                    </div>
                </div>
                
                <div className="col-5">
                    <MaterialesDetalleEvento evento={data} user={user} changePrice={changePrice} eventoID={eventoID} />
                </div>
            </div>
        </div>    
    </div>
    </PanelLayout>
}