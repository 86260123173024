import { useContext } from "react";
import { FormControlLabel,  Checkbox, FormGroup} from "@mui/material";
import { ClassContext } from "../../../Context/ClassContext";
import { useTranslation } from "react-i18next";

export default function SchedulerComp () {
    const { form, setForm } = useContext(ClassContext)
    const {t} = useTranslation()

    const dias = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"]
    const diasIngles = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

    function labelDay(diaEspanol) {
        const diasTraduccion = {
            "lunes": "Monday",
            "martes": "Tuesday",
            "miércoles": "Wednesday",
            "jueves": "Thursday",
            "viernes": "Friday",
            "sábado": "Saturday",
            "domingo": "Sunday"
        };
    
        // Convierte el día a minúsculas para manejar entradas en mayúsculas o minúsculas
        const diaMinusculas = diaEspanol.toLowerCase();
    
        // Verifica si el día proporcionado está en el objeto de traducciones
        if (diasTraduccion.hasOwnProperty(diaMinusculas)) {
            return diasTraduccion[diaMinusculas];
        } else {
            return "Día no válido";
        }
    }
    

    return <>
        <FormControlLabel control={
            <Checkbox 
                checked={form.recurrente}
                onChange={ev=>setForm(form=>({...form, recurrente: ev.target.checked }))}/>
        } label={t("clase.nueva.recurrente")} />
        
        <div className="col-md-12">
            <div className="row">
                <div class="mb-3 col-md-4 pl-0">
                    <label class="form-label">{t("clase.nueva.fecha")} <span className="text-danger">(required)</span>:</label>
                    <input type="date" value={form.fecha} onChange={ev=>setForm(form=>({...form, fecha: ev.target.value}))} required 
                        class="form-control input-default "  />
                </div>
                <div class="mb-3 col-md-4 pl-0">
                    <label class="form-label">{t("clase.nueva.inicio")} <span className="text-danger">(required)</span>:</label>
                    <input type="time" value={form.horaInicio} step={600} onChange={ev=>setForm(form=>({...form, horaInicio: ev.target.value}))} required 
                        class="form-control input-default "  />
                </div>
                <div class="mb-3 col-md-4 pr-0">
                    <label class="form-label">{t("clase.nueva.final")} <span className="text-danger">(required)</span>:</label>
                    <input type="time" value={form.horaFinal} onChange={ev=>setForm(form=>({...form, horaFinal: ev.target.value}))} required 
                        class="form-control input-default "  />
                </div>
            </div>
        </div>
        
        {form.recurrente && <div className="col-md-12">
            {/* Fila 1 - Repetir cada */}
            <div className="row">
                <label className="form-label mb-3 col-md-12 pl-0 font-weight-bold" style={{color: "black"}}>Lessons every:</label><br/>
                {/* {form.tipoRepetir === "dias" && <input 
                    type="number" 
                    min="1" 
                    value={form.repetirCada}
                    onChange={ev => setForm(form => ({...form, repetirCada: ev.target.value}))}
                    className="form-control input-default mb-3 col-md-12 pl-0" 
                    name="repetirCada"
                /> } */}
                <select 
                    value={form.tipoRepetir}
                    onChange={ev => setForm(form => ({...form, tipoRepetir: ev.target.value}))}
                    className="form-control input-default mb-3 col-md-12 pl-0"
                    name="tipoRepetir"
                >
                    {/* <option value="dias">días</option> */}
                    <option value="semana">Week</option>
                    <option value="mes">Month</option>
                </select>
            </div>
                
            {/* Fila 2 - Se repite el */}
            {form.tipoRepetir === "semana" && <div className="mb-4 mt-2 row d-flex flex-column">
                <label className="form-label font-weight-bold"  style={{color: "black"}}>Choose the days of the week it will repeat:</label>
                <div className="d-flex d-block">
                    {["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"].map(dia => (
                        <div key={dia}>
                            <input 
                                type="checkbox" 
                                checked={form.dias?.includes(dia)} className="ml-2 mr-2"
                                onChange={() => {
                                    if (form.dias?.includes(dia)) {
                                        setForm(form => ({...form, dias: form.dias.filter(d => d !== dia)}));
                                    } else {
                                        setForm(form => ({...form, dias: [...(form.dias || []), dia]}));
                                    }
                                }}
                            /> {labelDay(dia)}
                        </div>
                    ))}
                </div>
            </div>}

            {/* Fila 3 - Termina */}
            <div className="mb-3 row d-flex flex-column">
                <label className="form-label font-weight-bold"  style={{color: "black"}}>Recurring Event Ends in:</label>
                <div>
                    <input 
                        type="radio" 
                        value="nunca"
                        checked={form.termina === "nunca"}
                        onChange={ev => setForm(form => ({...form, termina: ev.target.value}))}
                    /> never
                </div>
                <div>
                    <input 
                        type="radio" 
                        value="el"
                        checked={form.termina === "el"}
                        onChange={ev => setForm(form => ({...form, termina: ev.target.value}))}
                    /> on this date
                    <input 
                        type="date"
                        value={form.fechaFinal}
                        onChange={ev => setForm(form => ({...form, fechaFinal: ev.target.value}))}
                        className="form-control input-default ml-2"
                        name="fechaFinal"
                    />
                </div>
                <div>
                    <input 
                        type="radio" 
                        value="despues"
                        checked={form.termina === "despues"}
                        onChange={ev => setForm(form => ({...form, termina: ev.target.value}))}
                    /> After 
                    <input 
                        type="number" 
                        value={form.repeticiones}
                        onChange={ev => setForm(form => ({...form, repeticiones: ev.target.value}))}
                        className="form-control input-default ml-2"
                        name="repeticiones"
                    /> lessons
                </div>
            </div>
        </div>
        }
    </>
}