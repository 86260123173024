import { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import Constants from "../../../Utilities/Constants";
import { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function ModalPreguntas({ open, setOpen, idExamen, obtenerDataExamenes }) {
    const [respuestas, setRespuestas] = useState([{ id: 1, correcta: true, texto: '' }]);
    const [contador, setContador] = useState(2);
    const [pregunta, setPregunta] = useState("");
    const { addToast } = useToasts();
    const editorRef = useRef(null);

    const clearData = () => {
        setRespuestas([{ id: 1, correcta: true, texto: '' }])
        setPregunta("")
    }

    const agregarRespuesta = () => {
        setRespuestas([...respuestas, { id: contador, correcta: false, texto: '' }]);
        setContador(contador + 1);
    };

    const eliminarRespuesta = (id) => {
        setRespuestas(respuestas.filter(respuesta => respuesta.id !== id));
    };

    const marcarComoCorrecta = (id) => {
        const nuevasRespuestas = respuestas.map(respuesta => {
            if (respuesta.id === id) {
                return { ...respuesta, correcta: true };
            } else {
                return { ...respuesta, correcta: false };
            }
        });
        setRespuestas(nuevasRespuestas);
    };

    const handleChangeRespuesta = (id, texto) => {
        const nuevasRespuestas = respuestas.map(respuesta => {
            if (respuesta.id === id) {
                return { ...respuesta, texto: texto };
            } else {
                return respuesta;
            }
        });
        setRespuestas(nuevasRespuestas);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log("el tiny", editorRef.current.getContent())

        const respuestaCorrecta = respuestas.filter(respuesta => respuesta.correcta);
        if (respuestaCorrecta.length === 0) {
            alert("No elegiste una respuesta correcta, tienes que hacerlo.");
            return;
        }

        // Envía los datos al backend
        // Aquí deberías hacer la lógica para enviar los datos al backend
        const datos = {
            respuestas: respuestas,
            idExamen: idExamen,
            pregunta: editorRef.current.getContent()
        };
        console.log(datos);
        // Aquí deberías enviar los datos al backend usando axios u otra librería
        const response = await axios.post(Constants.restURL()+"?seccion=registrarPregunta", datos);
        console.log("la reso", response)
        // Cierra el modal
        if(response.data.exito === 1){
            setOpen(false);
            addToast("The question has been added successfully", { appearance: 'success', autoDismiss: true });
            obtenerDataExamenes()
            clearData()
        }
        
    };

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <form onSubmit={handleSubmit}>
                    <h4 className="mb-3">Question:</h4>
                    {/* <input type="text" className="form-control" autoFocus name="pregunta" value={pregunta} onChange={(ev)=>setPregunta(ev.target.value)} required /> */}
                    <Editor
                        apiKey='vfuhg2cq740z9xtubfv3zilhrmx12nryhazea2zn5ae6jqmj'
                        onInit={(_evt, editor) => editorRef.current = editor}
                        initialValue="<p>This is the initial content of the editor.</p>"
                        
                        init={{
                        height: 500,
                        menubar: true,
                        plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons accordion',
                        toolbar: "undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl",
                        toolbar_mode: 'sliding',
                        quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',

                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
                    />
                    <h4 className="mt-3 mb-3">Answers:</h4>
                    { respuestas.map((respuesta, index) => <div className="row">
                        <div className="col-md-1">
                            <div id="columnaContadores" className="pull-right">
                                <p key={index}><span className="badge">{index + 1}</span></p>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div id="columnaRespuestas">
                                <p key={respuesta.id}>
                                    <input
                                        type="text"
                                        value={respuesta.texto}
                                        onChange={(e) => handleChangeRespuesta(respuesta.id, e.target.value)}
                                        className="respuesta form-control"
                                        required
                                    />
                                    
                                </p>
                            </div>
                        </div>
                        <div className="col-md-2">
                            { respuestas.length > 1 && <DeleteIcon onClick={() => eliminarRespuesta(respuesta.id)} />}
                            {respuesta.correcta ? <CheckCircleIcon color="primary" onClick={() => marcarComoCorrecta(respuesta.id)} /> : <CheckCircleOutlineIcon color="default" onClick={() => marcarComoCorrecta(respuesta.id)} />}
                        </div>
                    </div>)}

                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" onClick={() => setOpen(false)}>Cancel</button>
                        <button type="button" className="btn btn-default" onClick={agregarRespuesta}>Add answer</button>
                        <button type="submit" className="btn btn-primary">Save</button>
                    </div>
                </form>
            </Box>
        </Modal>
    );
}
