export default function AlumnosDetalleEvento({evento, user}) {
    return <div class="card">
        <div class="card-header">
            <h4 class="card-title">Guests</h4>
        </div>
        <div class="card-body">
            <div class="table-responsive recentOrderTable">
                <table class="table verticle-middle">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Account Type</th>
                            {user && user.type === "Teacher" && <th>Email</th>}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{evento.profesor.name}</td>
                            <td>Teacher</td>
                            {user && user.type === "Teacher" && <td>{evento.profesor.email}</td>}
                        </tr>
                        {evento.attendees.map(alumno=>{
                            return <tr>
                                <td>{alumno.name}</td>
                                <td>Student</td>
                                {user && user.type === "Teacher" && <td>{alumno.email}</td>}
                            </tr>})}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}