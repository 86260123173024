import { Route, Switch, useRouteMatch } from "react-router-dom";
import Actividades from "../Pages/Actividades";
import NuevaActividad from "../Pages/Actividades/form";
import Alumnos from "../Pages/Alumnos";
import NuevoAlumno from "../Pages/Alumnos/form";
import Classes from "../Pages/Clases";
import DetallesClase from "../Pages/Clases/Detalles";
import NewClass from "../Pages/Clases/form";
import DetalleEvento from "../Pages/DetalleEvento";
import Eventos from "../Pages/Eventos";
import Grupos from "../Pages/Grupos";
import DetailGroups from "../Pages/Grupos/Detail";
import NuevoGrupo from "../Pages/Grupos/form";
import SelectStudents from "../Pages/Grupos/SelectStudents";
import Horario from "../Pages/Horario";
import UpdateUser from "../Pages/UpdateUser/UpdateUser";
import PanelLayout from "../panelLayout/Layout";
import GruposAlumnos from "../StudentPages/Grupos";
import Profesores from "../StudentPages/Profesores";
import Indice from "../panelLayout/";
import StudentDetail from "../Pages/Alumnos/StudenDetails";
import GruposChat from "../Pages/Grupos/Chat";
import DetailGroupsStudent from "../StudentPages/Grupos/Detalles";
import ExamenesLista from "../Pages/Examenes/Lista";
import ExamenesRegistro from "../Pages/Examenes/Registrar";
import ExamenesActivos from "../Pages/Examenes/Activos";
import ExamenesPreguntas from "../Pages/Examenes/Preguntas";
import ExamenesMios from "../Pages/Examenes/Activos/mine";
import ExamenesAplicar from "../Pages/Examenes/Aplicar";
import ExamenesScore from "../Pages/Examenes/Score";
import ExamenesInforme from "../Pages/Examenes/Informes";
export default function PanelRouter() {
    let match = useRouteMatch();
    return <PanelLayout>
        <Switch>
            {/* 
                Exams section
            */}
            <Route exact path={`${match.path}/exams/active`}>
                <ExamenesActivos />
            </Route>
            <Route exact path={`${match.path}/exams/list`}>
                <ExamenesLista />
            </Route>
            <Route exact path={`${match.path}/exams/new`}>
                <ExamenesRegistro />
            </Route>
            <Route exact path={`${match.path}/exams/questions/:examID`}>
                <ExamenesPreguntas />
            </Route>
            <Route exact path={`${match.path}/exams/mine`}>
                <ExamenesMios />
            </Route>
            <Route exact path={`${match.path}/exams/score/:examID/:applicationID`}>
                <ExamenesScore />
            </Route>
            <Route exact path={`${match.path}/exams/apply/:examID`}>
                <ExamenesAplicar />
            </Route>
            <Route exact path={`${match.path}/exams/reports/:examID`}>
                <ExamenesInforme />
            </Route>
            {/* 
                Students section
            */}
            <Route exact path={`${match.path}/students/list`}>
                <Alumnos />
            </Route>
            <Route exact path={`${match.path}/students/new`}>
                <NuevoAlumno />
            </Route>
            <Route exact path={`${match.path}/students/edit/:alumnoID`}>
                <NuevoAlumno />
            </Route>
            <Route exact path={`${match.path}/students/detail/:studentID`}>
                <StudentDetail />
            </Route>
            {/* 
                Groups section
            */}
            <Route exact path={`${match.path}/groups/list`}>
                <Grupos />
            </Route>
            <Route exact path={`${match.path}/groups/new`}>
                <NuevoGrupo />
            </Route>
            <Route exact path={`${match.path}/groups/detail/:groupID`}>
                <DetailGroups />
            </Route>
            <Route exact path={`${match.path}/groups/chat/:groupID`}>
                <GruposChat />
            </Route>
            <Route exact path={`${match.path}/groups/edit/:grupoID`}>
                <NuevoGrupo />
            </Route>
            <Route exact path={`${match.path}/groups/select-students/:groupID`}>
                <SelectStudents />
            </Route>
            {/* 
                Classes section
            */}
            <Route exact path={`${match.path}/classes/list`}>
                <Classes />
            </Route>
            <Route exact path={`${match.path}/classes/new`}>
                <NewClass />
            </Route>
            <Route exact path={`${match.path}/classes/edit/:classID`}>
                <NewClass />
            </Route>
            <Route exact path={`${match.path}/classes/detail/:claseID`}>
                <DetallesClase />
            </Route>
            <Route exact path={`${match.path}/classes/chat/:claseID`}>
                <GruposChat />
            </Route>
            <Route exact path={`${match.path}/schedule`}>
                <Horario />
            </Route>
            {/* 
                Groups section
            */}
            <Route exact path={`${match.path}/updateUser`}>
                <UpdateUser />
            </Route>
            
            
            
            
            
            
            
            <Route exact path={`${match.path}/actividades`}>
                <Actividades />
            </Route>
            <Route exact path={`${match.path}/nueva-actividad`}>
                <NuevaActividad />
            </Route>
            <Route exact path={`${match.path}/editar-actividad/:actividadID`}>
                <NuevaActividad />
            </Route>
            
            
            
            
            <Route exact path={`${match.path}/eventos`}>
                <Eventos />
            </Route>
            <Route exact path={`${match.path}/detalle-evento/:eventoID`}>
                <DetalleEvento />
            </Route>
            <Route exact path={`${match.path}/evento-publico/:eventoID`}>
                <DetalleEvento />
            </Route>
            {/* <Route exact path={`${match.path}/nuevo-evento`}>
                <NuevoEvento />
            </Route>
            <Route exact path={`${match.path}/adminlapapemxn`}>
                <AdminEventos />
            </Route> */}
            <Route exact path={`${match.path}/students/teachers`}>
                <Profesores />
            </Route>
            <Route exact path={`${match.path}/students/groups`}>
                <GruposAlumnos />
            </Route>
            <Route exact path={`${match.path}/students/groups/details/:accountID/:groupID`}>
                <DetailGroupsStudent />
            </Route>

            <Route exact path={`${match.path}/students/groups/chat/:accountID/:groupID`}>
                <GruposChat />
            </Route>
            
            <Route exact path={`${match.path}/students/schedule`}>
                <Horario />
            </Route>

            <Route exact path={`${match.path}/`}>
                <Indice />
            </Route>
        </Switch>
    </PanelLayout>
}