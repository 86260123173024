import React, { useState } from "react"
import firebase from "firebase"
import { useHistory } from "react-router-dom";
import EmailInput from "../../Components/EmailInput/EmailInput"
import SubmitInput from "../../Components/SubmitButton/SubmitButton"
import { useToasts } from "react-toast-notifications"
require("firebase/auth")

export default function Recover() {
    const [email, setEmail] = useState('');
    const { addToast } = useToasts();
    const history = useHistory();
    const [loading, setLoadingVisible] = useState(false)

    const onChangeEmail = (e) => {
        setEmail(e.target.value);
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        try {
            setLoadingVisible(true)
            // Enviar correo de recuperación de contraseña usando la función de Firebase
            //const response = await Axios.post( `${Constants.functionsURL()}/recoverPassword`, { email} )
            firebase.auth().sendPasswordResetEmail(email).then(resp=>{
                console.log('Correo de recuperación enviado con éxito', resp);
                addToast("Password recovery email sent successfully. Please check your email inbox to proceed.", { appearance: 'success', autoDismiss: true });
                history.push("/usg/signin")
            }).catch((err)=>{
                console.log('Correo de recuperación no enviado', err);
                addToast(err.message, { appearance: 'error', autoDismiss: true });
                setLoadingVisible(false)
            })
            // Mostrar un mensaje de éxito o redirigir al usuario
            
        } catch (error) {
            console.error('Error al enviar el correo de recuperación', error.message);
            // Manejar el error y mostrar un mensaje al usuario
        }
    };
    return (loading ? <div style={{minHeight: 310, display: "flex", alignItems: "center"}}>
            <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
            </div>
        </div> :
        <React.Fragment>
            <h3 className="text-center mb-4">{"Recover your Password"}</h3>
            <form onSubmit={onSubmit}>
                {/* Componente de entrada de correo electrónico */}
                <EmailInput id="email" label={"Enter your Email"} value={email} onChange={onChangeEmail} required={true} />
                {/* Botón de envío */}
                <SubmitInput value={"Send recovery link"} />
            </form>
        </React.Fragment>
    )
}
