import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../panelLayout/Breadcrumb";
import PanelLayout from "../../panelLayout/Layout";
import { useTranslation } from "react-i18next";
import { LayoutContext } from "../../Context/LayoutContext";
import firebase from "firebase"
import LoadingComp from "./WizardComp/Loading";
import WizardComp from "./WizardComp/Wizard";
import { ClassProvider } from "../../Context/ClassContext";
require("firebase/database")

export default function NewClass() {
    const {t} = useTranslation()
    let { classID } = useParams();

    const pageTitle = classID ? "Edit Class" : t("clase.nueva.titulo")

    const { setTitle } = useContext(LayoutContext)
   // let { actividadID } = useParams();
    const [loading, setLoading] = useState(false)
    
    useEffect(()=>setTitle(pageTitle), [])
    
    
    return <PanelLayout title="Nueva Clase">
    <div className="content-body">
        <div className="container-fluid">
            <Breadcrumb pageTitles={[{label: t("migajas.inicio"), to: "/"}, {label: t("clase.nueva.bread"), to: "/panel/classes/list"}, pageTitle]} />
            <div className="row">
                <div className="col-12">
                    <div className="table-responsive table-hover fs-14">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">{pageTitle}</h4>
                            </div>
                            {!loading ? 
                                <ClassProvider setLoading={setLoading} classID={classID}>
                                    <WizardComp classID={classID}/>
                                </ClassProvider>: 
                                <LoadingComp/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>    
    </div>
    </PanelLayout>
}

