import firebase from "firebase/app"
import { UserProvider } from "./Context/UserContext"
import { LayoutProvider } from "./Context/LayoutContext"
import "./Utilities/Multilenguaje"
import "./App.css"
import MainRouter from "./Router/MainRouter"
import { ToastProvider } from "react-toast-notifications"
import i18n from "./Utilities/Multilenguaje"

i18n.changeLanguage("en")
console.log("la location", window.location.host)

if (!firebase.apps.length) {
    firebase.initializeApp({
        apiKey: "AIzaSyDF6CiQCrLDqtah99RWd-bMcGvXeiNbbCo",
        authDomain: "lapape-83eb0.firebaseapp.com",
        databaseURL: "https://lapape-83eb0-default-rtdb.firebaseio.com",
        projectId: "lapape-83eb0",
        storageBucket: "lapape-83eb0.appspot.com",
        messagingSenderId: "294742767671",
        appId: "1:294742767671:web:9e65f597c32882df99a7b2",
        measurementId: "G-K0M2FJTES2"
    })
    if(window.location.host === "localhost:3000"){
        var db = firebase.database();
        db.useEmulator("localhost", 9000);
        const auth = firebase.auth();
        auth.useEmulator("http://127.0.0.1:9099");
    }
} else {
    firebase.app() // if already initialized, use that one
}

function App() {
    return <ToastProvider>
        <UserProvider>
            <LayoutProvider>
                <MainRouter/>
            </LayoutProvider>
        </UserProvider>
    </ToastProvider>
}

export default App
