import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Breadcrumb from "../../panelLayout/Breadcrumb";
import PanelLayout from "../../panelLayout/Layout";
import firebase from "firebase"
import { UserContext } from "../../Context/UserContext";
require("firebase/database")

export default function NuevaActividad() {
    let { actividadID } = useParams();
    const [form, setForm] = useState({})
    const { user } = useContext(UserContext)
    const history = useHistory();

    const guardarGrupo = (ev) => {
        ev.preventDefault();
        console.log("el evento", form)
        const ref = firebase.database().ref("actividades").child(user.user_id)
        if(actividadID){
            ref.child(actividadID).update(form).then(()=>{
                history.push("/actividades")
            })
        } else {
            ref.push(form).then(()=>{
                history.push("/actividades")
            })
        }
        
        return false
    }
    const borrarMaterial = (index) => {
        let materials = form.materiales
        materials.splice(index, 1)
        setForm(form=>({...form, materiales: materials }))
    }
    const editarMaterial = (index, char, value) => {
        let formAux = form
        formAux.materiales[index][char] = value
        setForm(formAux)
    }
    useEffect(()=>{
        console.log("checo el params", actividadID)
        if(actividadID && user && user.user_id){
            firebase.database().ref("actividades").child(user.user_id).child(actividadID).once("value", snap=>{
                setForm(snap.val())
            })
        }
    }, [actividadID, user])
    return <PanelLayout title="Nueva Actividad">
    <div className="content-body">
        <div className="container-fluid">
            <Breadcrumb pageTitles={["Home", "Nueva Actividad"]} />
            
            <div className="row">
                <div className="col-12">
                    <div className="table-responsive table-hover fs-14">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Registrar nueva actividad</h4>
                            </div>
                            <div class="card-body">
                                <div class="basic-form">
                                    <form onSubmit={guardarGrupo}>
                                        <div class="mb-3">
                                            <label class="form-label">Nombre de la actividad:</label>
                                            <input type="text" value={form.nombre} onChange={ev=>setForm(form=>({...form, nombre: ev.target.value}))} required 
                                                class="form-control input-default " name="nombre" placeholder="Ejemplo: Creación de un sistema planetario" />
                                        </div>
                                        <div class="mb-3">
                                            <label class="form-label">Descripción de la actividad:</label>
                                            <textarea type="text" value={form.descripcion} rows={4} onChange={ev=>setForm(form=>({...form, descripcion: ev.target.value}))} required 
                                                class="form-control input-default " name="descripcion" placeholder="Ejemplo: Es una actividad para desarrollar..." />
                                        </div>
                                        <div className="table-responsive table-hover fs-14">
                                            <table className="table display mb-4 dataTablesCard  card-table table-stripped">
                                                <thead>
                                                    <tr>
                                                        <th>Material</th>
                                                        <th>Cantidad</th>
                                                        <th>Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody> 
                                                    {
                                                        form.materiales && form.materiales.map((material, index)=>{
                                                            return <tr>
                                                                <td>
                                                                    <input type="text" value={material.nombre} onChange={ev=>editarMaterial(index, "nombre", ev.target.value)} required 
                                                                        class="form-control input-default " name="nombreMaterial" placeholder="Ejemplo: Bola de Unicel Grande" />
                                                                </td>
                                                                <td>
                                                                    <input type="number" value={material.cantidad} onChange={ev=>editarMaterial(index, "cantidad", ev.target.value)} required 
                                                                        class="form-control input-default " name="cantidadMaterial" placeholder="Ejemplo: 3" />    
                                                                </td>
                                                                <td>
                                                                    <div class="d-flex">
                                                                        <button type="button" onClick={()=>borrarMaterial(index)} class="btn btn-danger ml-2 shadow btn-xs sharp"><i class="fa fa-trash"></i></button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex flex-row-reverse">
                                            <button type="submit" class="btn btn-primary ml-3 mt-4">Guardar actividad</button>
                                            <button type="button" onClick={()=>setForm(form=>({...form, materiales: form.materiales ? [...form.materiales, {}] : [{}]}))} 
                                                class="btn btn-default mt-4">Añadir material</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
    </div>
    </PanelLayout>
}