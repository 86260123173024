import React, { useState, useContext, useEffect } from "react"
import { UserContext } from "../../Context/UserContext"
import { NavLink, useLocation, useHistory } from "react-router-dom"
import EmailInput from "../../Components/EmailInput/EmailInput"
import TextInput from "../../Components/TextInput/TextInput"
import PasswordInput from "../../Components/PasswordInput/PasswordInput"
import SubmitInput from "../../Components/SubmitButton/SubmitButton"
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next"
require("firebase/database")

export default function SignUp() {
    const location = useLocation()
    const {t} = useTranslation()
    const { addToast } = useToasts();
    const history = useHistory();
    const { signUpUser, isAnUserAuthenticated } = useContext(UserContext)
    const [loading, setLoadingVisible] = useState(false)
    const [errorField, seterrorField] = useState("")

    const [user, setUser] = useState({
        name: "",
        email: "",
        password: "",
        passwordConfirmation: "",
    })

    useEffect(() => {
        if (isAnUserAuthenticated !== undefined && isAnUserAuthenticated !== false) history.push("/")
    }, [isAnUserAuthenticated])


    const OnSubmit = async (event) => {
        event.preventDefault()
        console.log("el lugar", location.pathname)
        setLoadingVisible(true)

        const { name, email, password, passwordConfirmation } = user

        if (!name.trim() || !email.trim() || !password.trim() || !passwordConfirmation.trim()) {
            addToast(t("All fields are mandatory."), { appearance: 'error', autoDismiss: true });
            setLoadingVisible(false)
            return
        }

        if (password.trim().length < 6) {
            addToast(t("The password must have at least 6 characters."), { appearance: 'error', autoDismiss: true });
            setLoadingVisible(false)
            return
        }

        if (!(password.trim() === passwordConfirmation.trim())) {
            addToast(t("Passwords must be the same."), { appearance: 'error', autoDismiss: true });
            setLoadingVisible(false)
            return
        }
        const type = location.pathname === '/usg/signup' ? "Teacher": "Student"
        const result = await signUpUser(email, password, name, type)

        console.log(result)

        if (!result) {
            addToast(t("An error occurred, please try again later."), { appearance: 'error', autoDismiss: true });
            setLoadingVisible(false)
            return
        }
        setLoadingVisible(false)
        history.replace("/usg/signin")
    }

    const OnChangeText = (event) => {
        const { id, value } = event.target
        setUser({
            ...user,
            ...{
                [id]: value,
            },
        })
    }

    return loading ? <div style={{minHeight: 540, display: "flex", alignItems: "center"}}>
        <div className="sk-three-bounce">
            <div className="sk-child sk-bounce1"></div>
            <div className="sk-child sk-bounce2"></div>
            <div className="sk-child sk-bounce3"></div>
        </div>
    </div> : <React.Fragment>
        <h3 className="text-center mb-4">{t("registrarse.titulo")}</h3>
        <form onSubmit={OnSubmit}>
            <TextInput
                id="name"
                label={t("registrarse.nombre")}
                value={user.name}
                onChange={OnChangeText}
                required={true}
            />
            <EmailInput id="email" label={t("registrarse.correo")} value={user.email} onChange={OnChangeText} required={true} />
            <PasswordInput id="password" label={t("registrarse.contrasena")} value={user.password} onChange={OnChangeText} required={true} />
            <PasswordInput
                id="passwordConfirmation"
                confirmation={true}
                label={t("registrarse.confirma")}
                value={user.passwordConfirmation}
                onChange={OnChangeText}
                required={true}
            />
            <SubmitInput value={t("registrarse.boton")} />
        </form>
        <div className="new-account mt-3">
            <p>
                {t("registrarse.yatienes")}{" "}
                <NavLink to="/signin" className="text-primary">
                    {t("registrarse.acceder")}
                </NavLink>
            </p>
        </div>
    </React.Fragment>
}
