import React from "react"
import { NavLink } from "react-router-dom"

export default function Breadcrumb({ pageTitles }) {
    return (
        <React.Fragment>
            {pageTitles && pageTitles[0] ? (
                <div className="page-titles">
                    <ol className="breadcrumb">
                        {pageTitles.map((title, i) => {
                            return typeof title == "string" ? <li className={`breadcrumb-item ${i === pageTitles.length - 1 ? "active" : ""}`} key={i}>
                                <a>{title}</a>
                            </li> : <li className={`breadcrumb-item ${i === pageTitles.length - 1 ? "active" : ""}`} key={i}>
                                <NavLink to={title.to}>{title.label}</NavLink>
                            </li>
                        })}
                    </ol>
                </div>
            ) : (
                <div></div>
            )}
        </React.Fragment>
    )
}
