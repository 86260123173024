import { Route, Switch, useRouteMatch } from "react-router-dom";
import SignIn from "../Forms/SignIn/SignIn";
import SignUp from "../Forms/SignUp/SignUp";
import ContainerForm from "../Pages/ContainerForm/ContainerForm";
import Recover from "../Forms/Recover/Recover";

export default function UnSigned() {
    let match = useRouteMatch();
    console.log("el matcgh", match)
    return <ContainerForm>
        <Switch>
            <Route  path={`${match.path}/signin`}>
                <SignIn />
            </Route>
            <Route  path={`${match.path}/signup`}>
                <SignUp />
            </Route>
            <Route  path={`${match.path}/student-signup`}>
                <SignUp />
            </Route>
            <Route  path={`${match.path}/recover-password`}>
                <Recover />
            </Route>
        </Switch>
    </ContainerForm>
}