import { useContext, useEffect, useState } from "react";
import { useParams, NavLink, useHistory } from "react-router-dom";
import firebase from "firebase"
import Breadcrumb from "../../../panelLayout/Breadcrumb";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../Context/UserContext";
import { useToasts } from "react-toast-notifications";
import { LayoutContext } from "../../../Context/LayoutContext";
import InternalNotes from "../../../Components/InternalNotes";
require("firebase/database")

export default function StudentDetail(){
    const {t} = useTranslation()
    const { addToast } = useToasts();
    const { studentID } = useParams();
    const { user } = useContext(UserContext)
    const history = useHistory();
    const { setTitle } = useContext(LayoutContext)
    const [studentData, setStudentData] = useState({})

    const deleteStudent = (alumnoID, alumnoNombre) => {
        const response = window.confirm(`${t("students.list.eliminar")} ${alumnoNombre}?`)
        if(response) {
            firebase.database().ref("alumnos").child(user.user_id).child(alumnoID).set(null)
            addToast("The Student has been deleted successfully", { appearance: 'success', autoDismiss: true });
            history.push("/panel/students/list")
        }
    }

    useEffect(()=>{
        console.log("checo el params", studentID)
        if(studentID && user && user.user_id){
            firebase.database().ref("alumnos").child(user.user_id).child(studentID).on("value", snap=>{
                const student = snap.val()
                if(student) setStudentData(student)
                else setStudentData({})                        
            })
        }
    }, [studentID])

    useEffect(()=>setTitle("Student Details"), [])

    return <div className="content-body">
        <div className="container-fluid">
            <Breadcrumb pageTitles={[{label: t("migajas.inicio"), to: "/"}, {label: "Students List", to: "/panel/students/list"}, "Student Details"]} />
            
            <div className="row">
                <div className="col-md-12">
                    <div class="form-head d-flex mb-3 mb-md-5 align-items-center  flex-row-reverse">
                        {/* <button class="ms-auto ml-3 rounded-0 btn bgl-danger text-primary" onClick={() => borrarGrupo(groupID, groupData.nombre)} >
                            {t("grupos.lista.drop.borrar")}
                        </button>
                        <NavLink class="ms-auto ml-3 rounded-0 btn bgl-primary text-primary" to={`/panel/groups/edit/${groupID}`} component="a">
                            {t("grupos.lista.drop.generales")}
                        </NavLink>
                        <NavLink class="ms-auto rounded-0 btn bgl-primary text-primary" to={`/panel/groups/select-students/${groupID}`} component="a">
                            {t("grupos.lista.drop.editar")}
                        </NavLink> */}
                        <button onClick={ev => deleteStudent(studentID, studentData.nombre)} class="ms-auto ml-3 rounded-0 btn bgl-danger text-primary">Delete Student</button>
                        <NavLink to={`/panel/students/edit/${studentID}`} class="ms-auto ml-3 rounded-0 btn bgl-primary text-primary">Edit Student Data</NavLink>
                        
                    </div>
                </div>
                <div className="col-md-12 mb-4">
                    <div className="card mb-xl-0 h-auto">
                        <div className="card-body">
                            <div class="row">
                                <div class="col-sm-5 mb-4">
                                    <p class="mb-2">Student Name</p>
                                    <h4 class="text-black text-uppercase">{studentData.nombre}</h4>
                                </div>
                                <div class="col-sm-7">
                                    <p class="mb-2">Email</p>
                                    <h4 class="text-black text-uppercase">{studentData.correo}</h4>
                                </div>
                                <div class="col-sm-5">
                                    <p class="mb-2">Phone</p>
                                    <h4 class="text-black">{studentData.phone ? studentData.phone : "-"}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {user && user.user_id && <div className="col-md-12">
                    <InternalNotes route={`internal-notes/${user.user_id}/students/${studentID}`}/>
                </div>}
            </div>
        </div>    
    </div>
}