import { NavLink } from "react-router-dom";
import Breadcrumb from "../../../panelLayout/Breadcrumb";
import PanelLayout from "../../../panelLayout/Layout";
import { useContext, useEffect, useState } from "react";
import { LayoutContext } from "../../../Context/LayoutContext";
import axios from "axios";
import Constants from "../../../Utilities/Constants";

export default function ExamenesActivos() {
    const { setTitle } = useContext(LayoutContext);

    useEffect(() => {
        setTitle("Exams List");
        fetchData();
    }, []);

    const [examenes, setExamenes] = useState([]);

    useEffect(() => {
        fetchData();
        const intervalId = setInterval(reloj, 1000);
        return () => clearInterval(intervalId);
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(Constants.restURL()+"?seccion=obtenerExamenesActivos");
            setExamenes(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const reloj = () => {
        setExamenes(prevExamenes => {
            return prevExamenes.map(examen => {
                let horas = parseInt(examen.horas);
                let minutos = parseInt(examen.minutos);
                let segundos = parseInt(examen.segundos) + 1;
                
                let siguienteMinutos = minutos;
                let siguienteHoras = horas;
                if (segundos === 60) {
                    segundos = 0;
                    siguienteMinutos = (minutos + 1) % 60;
                    siguienteHoras = horas + Math.floor((minutos + 1) / 60);
                }

                return {
                    ...examen,
                    horas: siguienteHoras.toString().padStart(2, "0"),
                    minutos: siguienteMinutos.toString().padStart(2, "0"),
                    segundos: segundos.toString().padStart(2, "0")
                };
            });
        });
    };

    return (
        <PanelLayout title="Exams List">
            <div className="content-body">
                <div className="container-fluid">
                    <Breadcrumb pageTitles={["Home", "Exams List"]} />
                    <div class="form-head d-flex mb-3 mb-md-5 align-items-center  flex-row-reverse">
                        <NavLink class="ms-auto rounded-0 btn bgl-primary text-primary" to={`/panel/exams/new`} component="a">
                            Build new Exam
                        </NavLink>
                    </div>
                    <div className="row">
                        {examenes.map((examen, index) => (
                            <div className="col-12" key={index}>
                                <div className="card text-center">
                                    <div className="card-header">
                                        <h5 className="card-title">{examen.nombre}</h5>
                                    </div>
                                    <div className="card-body">
                                        <div class="col-md-9">
                                            <p class="contadores subtituloMini">Activo por: <span class="horas">{examen.horas}</span> hora(s) 
                                            <span class="minutos"> {examen.minutos}</span> minuto(s) <span class="segundos">{examen.segundos}</span> segundo(s)</p> 
                                        </div>
                                        <div class="col-md-3">
                                            <a class="btn btn-default pull-right" href="estado.php"><span class="glyphicon glyphicon-time"></span> Permisos del examen</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </PanelLayout>
        
    );
}
