import { Step, StepLabel, Stepper } from "@mui/material";
import AssignmentComp from "./Assignment";
import SchedulerComp from "./Scheduler";
import ClassDetailsComp from "./ClassDetails";
import { ClassContext } from "../../../Context/ClassContext";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import Reminders from "./Reminders";

export default function WizardComp ({ classID }) {
    const {t} = useTranslation()
    const { step, setStep, setForm, formSubmit } = useContext(ClassContext)

    const stepsEnglish = [
        "General Data", "Assignment", "Schedule programming", "Reminders"
    ]
    const stepsMapping = (label) => (
        <Step key={label} >
            <StepLabel>{label}</StepLabel>
        </Step>
    )
    return <div class="card-body">
        <Stepper activeStep={step} alternativeLabel style={{marginBottom:40}} >
            {stepsEnglish.map(stepsMapping)}
        </Stepper>
        <div className="basic-form mt-4">
            <form onSubmit={formSubmit} className="mt-4">
                
                {step === 0 && <ClassDetailsComp/>}
                {step === 1 && <AssignmentComp classID={classID}/>}
                {step === 2 && <SchedulerComp/>}
                {step === 3 && <Reminders/>}
                <div className="d-flex flex-row-reverse">
                    {step === 3 && <button type="submit" class="btn btn-primary ml-3 mt-4">{t("clase.nueva.boton.guardar")}</button>}
                    {(step === 0 || step === 1 || step === 2) && <button type="button" onClick={()=>setStep(step=>(step+1))} class="btn btn-info ml-3 mt-4">{t("clase.nueva.boton.siguiente")}</button>}
                    {(step === 1 || step === 2|| step === 3) && <button type="button" onClick={()=>setStep(step=>(step-1))} class="btn btn-info ml-3 mt-4">{t("clase.nueva.boton.anterior")}</button>}
                    
                    {step === 0 && <button type="button" onClick={()=>setForm(form=>({...form, materiales: form.materiales ? [...form.materiales, {}] : [{}]}))} 
                        class="btn btn-default mt-4">{t("clase.nueva.boton.anadir")}</button>}
                    {step === 0 && <button type="button" onClick={()=>setForm(form=>({...form, links: form.links ? [...form.links, {}] : [{}]}))} 
                        class="btn btn-default mt-4">{"Add Links"}</button>}
                </div>
            </form>
        </div>
    </div>
}