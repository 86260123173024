import firebase from "firebase"
import { useEffect, useState } from "react";
require("firebase/database")

export default function InternalNotes ({route}) {
    const [note, setNote] = useState("")
    const [noteList, setNoteList] = useState([])
    const submitForm = (ev) => {
        ev.preventDefault();
        console.log("el ev", note, route)
        const now = new Date()
        firebase.database().ref(route).child("notes").push({
            time: now.getTime(),
            note
        })
        setNote("")
    }
    function getEnglishDate(timestamp) {
        // Crear un objeto Date con el timestamp proporcionado
        const date = new Date(timestamp);
        
        // Obtener los componentes de la fecha
        const year = date.getFullYear();
        const month = date.toLocaleString('en-US', { month: 'long' }); // Nombre completo del mes
        const day = date.getDate();
    
        // Formatear la fecha en inglés
        const englishDate = `${month} ${day}, ${year}`;
    
        return englishDate;
    }
    useEffect(()=>{
        firebase.database().ref(route).child("notes").on("value", snap=>{
            let notes = snap.val()
            console.log("las notesson", notes)
            if(notes) setNoteList(Object.values(notes))
        })
    }, [])
    return <div className="card">
        <div class="card-header d-sm-flex d-block pb-0 border-0">
            <div>
                <h4 class="text-black fs-20">Private Notes</h4>
            </div>
        </div>
        <div className="card-body">
            <form className="right-box-padding" onSubmit={submitForm}>
                
                <div className="read-content">
                    {noteList.map((singleNote)=><>
                        <div className="read-content-body text-black">
                            {singleNote.note}
                        </div>
                        <h6 className="text-muted text-right">
                            Created on: {getEnglishDate(singleNote.time)}
                        </h6>
                        <hr/>
                    </>)}
                    <div className="mb-3 pt-3">
                        <textarea value={note} onChange={ev=>setNote(ev.target.value)} required name="write-email" id="write-email" cols="30" rows="2" className="form-control" placeholder="Add an internal note visible only to you."></textarea>
                    </div>
                </div>
                <div className="text-end">
                    <button className="btn btn-primary ">Save</button>
                </div>
            </form>
                        
        </div>
    </div>
}