import React, { useState, useContext, useEffect } from "react"
import { UserContext } from "../../Context/UserContext"
import { NavLink, Redirect } from "react-router-dom"
import EmailInput from "../../Components/EmailInput/EmailInput"
import PasswordInput from "../../Components/PasswordInput/PasswordInput"
import SubmitInput from "../../Components/SubmitButton/SubmitButton"
import { useTranslation } from "react-i18next"

export default function SignIn() {
    const { t } = useTranslation()
    const { signInUser, isAnUserAuthenticated } = useContext(UserContext)

    const [errorField, seterrorField] = useState("")

    const [redirect, setRedirect] = useState(undefined)

    const [user, setUser] = useState({
        email: "",
        password: "",
    })

    useEffect(() => {
        if(isAnUserAuthenticated !== undefined)
            setRedirect(isAnUserAuthenticated ? "/" : false)
    }, [isAnUserAuthenticated])

    const OnSubmit = async (event) => {
        event.preventDefault()

        const { email, password } = user

        if (!email.trim() || !password.trim()) {
            seterrorField("All fields are required")
            return
        }

        const result = await signInUser(email, password)

        if (!result) {
            seterrorField("Error ocurred, try later")
            return
        }else if(result.message){
            seterrorField(result.message)
            return
        }
        setRedirect("signup")
    }

    const OnChangeText = (event) => {
        const { id, value } = event.target
        seterrorField("");
        setUser({
            ...user,
            ...{
                [id]: value,
            },
        })
    }

    return (
        <React.Fragment>
            { redirect ? (
                <Redirect to={redirect} />
            ) : (
                <React.Fragment>
                    <h3 className="text-center mb-4">{t("acceder.titulo")}</h3>
                    <form onSubmit={OnSubmit}>
                        <EmailInput id="email" label={t("acceder.correo")} value={user.email} onChange={OnChangeText} required={true} />
                        <PasswordInput id="password" label={t("acceder.contrasena")} value={user.password} onChange={OnChangeText} required={true} />
                        <SubmitInput value={t("acceder.boton")} />
                    </form>
                    <div className="mt-3">
                        <p className="text-center">{errorField}</p>
                    </div>
                    <div className="new-account mt-3">
                        <p>
                            <NavLink to="/usg/recover-password" className="text-primary">
                                Forgot Password?
                            </NavLink>
                        </p>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}
