export default function GrupoDetalleEvento ({evento}) {
    return <div class="card overflow-hidden">
        <div class="text-center p-3 overlay-box">
            <img src="images/profile/profile.png" width="100" class="img-fluid rounded-circle" alt=""/>
            <h3 class=" mb-0 text-white">Grupo: {evento.grupo?.nombre}</h3>
            <h4 class="mt-3 mb-4 text-white">Estado: {evento.estado}</h4>
        </div>
        <div class="card-body">
            <div class="row text-center">
                <div class="col-6">
                    <div class="bgl-primary rounded p-3">
                        <h4 class="mb-0">{evento.grupo?.turno}</h4>
                        <small>Turno</small>
                    </div>
                </div>
                <div class="col-6">
                    <div class="bgl-primary rounded p-3">
                        <h4 class="mb-0">{evento.fecha}</h4>
                        <small>Fecha</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
}