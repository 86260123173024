export default function GroupsList(t, data, history) {
    return <div className="table-responsive table-hover fs-14">
        <table className="table display dataTablesCard  card-table table-stripped table-wmargin" style={{ marginBottom: 150 }}>
            <thead>
                <tr>
                    <th>{t("grupos.lista.tabla.nombre")}</th>
                    <th>{t("grupos.lista.tabla.escuela")}</th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(data).map(char => {
                    const grupo = data[char];
                    return <tr style={{cursor: "pointer", textTransform: "uppercase"}} onClick={() => history.push(`/panel/groups/detail/${char}`)}>
                        <td>{grupo.nombre}</td>
                        <td>{grupo.escuela ? grupo.escuela : "-"}</td>
                    </tr>;
                })}
            </tbody>
        </table>
    </div>;
}