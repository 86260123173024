import React, { useState, useContext, useEffect } from "react"
import firebase from "firebase"
import Axios from "axios"
import { UserContext } from "../../Context/UserContext"
import { LayoutContext } from "../../Context/LayoutContext"
import Layout from "../../panelLayout/Layout"
import TextInput from "../../Components/TextInput/TextInput"
import SubmitInput from "../../Components/SubmitButton/SubmitButton"
import Constants from "../../Utilities/Constants"
import { useToasts } from "react-toast-notifications"
require("firebase/storage")

export default function UpdateUser() {
    const { user } = useContext(UserContext)
    const { setLoadingVisible } = useContext(LayoutContext)
    const { addToast } = useToasts();
    const [errorField, seterrorField] = useState("")

    const [userInfo, setUserInfo] = useState({
        name: "",
        email: "",
    })

    const changeUserData = async (_data) => {
        const response = await Axios.post(
            `${Constants.functionsURL()}/updateUserData`, _data
        )
        console.log("la resp es ", response)
        addToast("The user data has been successfully updated. Please log in again to see the changes reflected.", { appearance: 'success', autoDismiss: true });
        setLoadingVisible(false)
    }
    

    useEffect(() => {
        setUserInfo({ name: user.name, email: user.email })
    }, [user])
 

    const OnSubmit = async (event) => {
        event.preventDefault()
        if(!user.user_id) return false;
        
        setLoadingVisible(true)
        const fileInput = document.getElementById('fileInput');
        const file = fileInput.files[0];
        
        let newUserData = {name: userInfo.name, userID: user.user_id}
        if(file){
            const filemb = file.size/1000
            if(filemb > 1000){
                addToast("The file must be less than 1 megabyte", { appearance: 'error', autoDismiss: true });
                setLoadingVisible(false)
                return false
            }
            const storageRef = firebase.storage().ref();
            const avatarRef = storageRef.child(`avatars/${user.user_id}/${file.name}`);

            avatarRef.put(file)
                .then(snapshot => {
                    console.log('Avatar subido con éxito');
                    return snapshot.ref.getDownloadURL(); // Obtener la URL de descarga
                })
                .then(async avatarUrl => {
                    console.log('URL del avatar:', avatarUrl);
                    newUserData.avatarUrl = avatarUrl
                    await changeUserData(newUserData)
                    // Puedes utilizar la URL del avatar como desees en tu aplicación.
                })
                .catch(error => {
                    console.error('Error al subir el avatar:', error);
                });
        } else {
            await changeUserData(newUserData)
        }
        
        

        // const { name, telegramID } = userInfo

        // if (!name.trim() || !telegramID.trim()) {
        //     seterrorField("Todos los campos son obligatorios")
        //     return
        // }

        // const result = await updateUserInfo(name, telegramID)

        // console.log(result)

        // setLoadingVisible(false)
        // if (!result) {
        //     seterrorField("Ocurrio un error, intente mas tarde")
        //     return
        // }
        // setRedirect(true)
    }

    const OnChangeText = (event) => {
        const { id, value } = event.target
        seterrorField("")
        setUserInfo({
            ...userInfo,
            ...{
                [id]: value,
            },
        })
    }

    return (
        <Layout title="Actualizar información">
            <div className="content-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">
                                        Update profile information
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <div className="basic-form">
                                        <form onSubmit={OnSubmit}>
                                            <TextInput
                                                id="name"
                                                label="Full Name"
                                                value={userInfo.name}
                                                onChange={OnChangeText}
                                                required={true}
                                            />
                                            <TextInput
                                                id="email"
                                                label="Email"
                                                value={userInfo.email}
                                                onChange={OnChangeText}
                                                readOnly={true}
                                                disabled={true}
                                            />
                                            <div className="form-group">
                                                <label className="mb-1">
                                                    <strong>Profile Picture</strong>
                                                </label>
                                                <input type="file" className="form-control" id="fileInput" accept="image/*" />
                                            </div>
                                            <SubmitInput value="Save data" />
                                        </form>
                                        <div className="mt-3">
                                            <p className="text-center">{errorField}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
