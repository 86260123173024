import { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Breadcrumb from "../../panelLayout/Breadcrumb";
import PanelLayout from "../../panelLayout/Layout";
import firebase from "firebase"
import { UserContext } from "../../Context/UserContext";
require("firebase/database")

export default function Actividades() {
    const [data, setData] = useState({})
    const { user } = useContext(UserContext)
    const borrarGrupo = (actividadID, actividadNombre) => {
        const response = window.confirm(`¿Está seguro que desea eliminar la actividad ${actividadNombre}?`)
        if(response) firebase.database().ref("actividades").child(user.user_id).child(actividadID).set(null)
    }
    useEffect(()=>{
        if(!user.escuelaID) return false
        firebase.database().ref("actividades").child(user.user_id).on("value", snap=>{
            const grupos = snap.val()
            console.log("los grupos son", grupos)
            if(grupos) setData(grupos)
            else setData({})                        
        })
    }, [user])
    return <PanelLayout title="Gestión de Actividades">
    <div className="content-body">
        <div className="container-fluid">
            <Breadcrumb pageTitles={["Home", "Gestión de Actividades"]} />
            
            <div className="row">
                <div className="col-12">
                    <div class="form-head d-flex mb-3 mb-md-5 align-items-center  flex-row-reverse">
                        <NavLink class="ms-auto rounded-0 btn bgl-primary text-primary" to="/nueva-actividad" component="a">
                            Nueva Actividad
                        </NavLink>
                    </div>
                    <div className="table-responsive table-hover fs-14">
                        <table className="table display mb-4 dataTablesCard  card-table table-stripped">
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody> 
                                {
                                    Object.keys(data).map(char=>{
                                        const grupo = data[char]
                                        return <tr>
                                            <td>{grupo.nombre}</td>
                                            <td>
                                                <div class="d-flex">
                                                    <NavLink to={`/editar-actividad/${char}`} class="btn btn-secondary shadow btn-xs ml-2 sharp me-1"><i class="fa fa-pencil"></i></NavLink>
                                                    <button onClick={ev=>borrarGrupo(char, grupo.nombre)} class="btn btn-danger ml-2 shadow btn-xs sharp"><i class="fa fa-trash"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>    
    </div>
    </PanelLayout>
}