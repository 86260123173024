import { useContext, useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Breadcrumb from "../../panelLayout/Breadcrumb";
import PanelLayout from "../../panelLayout/Layout";
import firebase from "firebase"
import { UserContext } from "../../Context/UserContext";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { LayoutContext } from "../../Context/LayoutContext";
require("firebase/database")

export default function Classes() {
    const {t} = useTranslation()
    const { addToast } = useToasts();
    const { setTitle } = useContext(LayoutContext)
    const [data, setData] = useState([])
    const { user } = useContext(UserContext)
    const history = useHistory();

    const borrarGrupo = (actividadID, actividadNombre) => {
        const response = window.confirm(`¿Está seguro que desea eliminar la actividad ${actividadNombre}?`)
        if(response) firebase.database().ref("actividades").child(user.user_id).child(actividadID).set(null)
    }
    useEffect(()=>{
        if(!user.user_id) return false
        firebase.database().ref("clases").child(user.user_id).on("value", snap=>{
            const clases = snap.val()
            console.log("los clases son", clases)
            if(clases){
                setData(Object.values(clases))
            } 
        })
    }, [user])
    useEffect(()=>setTitle(t("clase.lista.titulo")), [])
    return <div className="content-body">
        <div className="container-fluid">
            <Breadcrumb pageTitles={[{label: t("migajas.inicio"), to: "/"}, t("clase.lista.titulo")]} />
            
            <div className="row">
                <div className="col-12">
                    <div class="form-head d-flex mb-3 mb-md-5 align-items-center  flex-row-reverse">
                        <NavLink class="ms-auto rounded-0 btn bgl-primary text-primary" to="/panel/classes/new" component="a">
                            {t("clase.lista.boton")}
                        </NavLink>
                    </div>
                </div>
                <div className="col-12">
                <div className="table-responsive table-hover fs-14">
                    <table className="table display mb-4 dataTablesCard  card-table table-stripped">
                        <thead>
                            <tr>
                                <th>{t("clase.lista.tabla.nom")}</th>
                                <th>{t("clase.lista.tabla.lugar")}</th>
                                <th>{t("clase.lista.tabla.fecha")}</th>
                                <th>{t("clase.lista.tabla.asignado")}</th>
                                <th>{t("clase.lista.tabla.estado")}</th>
                            </tr>
                        </thead>
                        <tbody> 
                            {
                                data.map(clase=>{
                                    return <tr>
                                        <td>{clase.nombre}</td>
                                        <td>{clase.tipo}</td>
                                        <td>{clase.fechayhora}</td>
                                        <td>{clase.asignadoA} - {clase.asignadoA == "grupo" ? clase.grupoSeleccionado.label : clase.alumnoSeleccionado.label}</td>
                                        <td>{clase.estado}</td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>    
    </div>
}

function renderEventContent(eventInfo) {
    console.log("la info del evento", eventInfo)
    const original = eventInfo.event._def.extendedProps.original
    console.log("la original del evento", original)
    const fechayhorastamp = new Date(original.fechayhora)
    const isPast = new Date(original.fechayhora) < new Date()
    return (
      <div style={{ color: "white", width: "100%", boxSizing: "border-box", padding: 10, backgroundColor:  isPast ? "#5f5f5f" : "#6418C3", textTransform: "capitalize"}}>
        <b>{original.nombre}<br/>
        Hora: {original.fechayhora.split("T")[1]}<br/>
        {original.asignadoA} - {original.asignadoA === "alumno" ? original.alumnoSeleccionado.label : original.grupoSeleccionado.label}</b>
      </div>
    )
  }