export default function AlumnosDetalleEvento({evento}) {
    return <div class="card">
        <div class="card-header">
            <h4 class="card-title">Alumnos del grupo</h4>
        </div>
        <div class="card-body">
            <div class="table-responsive recentOrderTable">
                <table class="table verticle-middle table-responsive-md">
                    <thead>
                        <tr>
                            <th scope="col">Alumno</th>
                            <th scope="col">Padre/Tutor</th>
                            <th scope="col">Estado</th>
                        </tr>
                    </thead>
                    <tbody>
                        {evento.alumnos && Object.keys(evento.alumnos).map(idx=>{
                            const alumno = evento.alumnos[idx]
                            return <tr>
                                <td>{alumno.nombre}</td>
                                <td>{alumno.padre}</td>
                                <td><span class="badge badge-rounded badge-default">Sin Enviar</span></td>
                            </tr>})}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}