import axios from "axios";
import Constants from "../../Utilities/Constants";

export default function MaterialesDetalleEvento({evento, user, changePrice, eventoID}) {
    const formatoPesos = new Intl.NumberFormat('es-MX', { maximumSignificantDigits: 2 })
    const obtenSubtotal = () => {
        let sumatoria = 0
        evento.actividad?.materiales?.map(material=>material.subtotal ? sumatoria+=parseInt(material.subtotal) : null)
        return sumatoria
    } 
    const enviarPrecios = async() => {
        let materialesAux = [...evento.actividad.materiales]
        const response = await axios.post(
            `${Constants.functionsURL()}/enviarMensajes`,
            { eventoID, escuelaID: user.escuelaID, userID: user.user_id, materiales: materialesAux}
        )
        if (response.data.success === 1) {
            alert("Se han enviado los mensajes exitosamente.")
        }
    }
    return <div class="card">
        <div class="card-header">
            <h4 class="card-title">Materiales a utilizar</h4>
        </div>
        <div class="card-body">
            <div class="table-responsive recentOrderTable">
                <table class="table verticle-middle ">
                    <thead>
                        <tr>
                            <th scope="col">Material</th>
                            <th scope="col">Cantidad</th>
                            <th scope="col">Precio</th>
                            {user.email === "criztian.torr3s@gmail.com" 
                                && <th scope="col">Subtotal</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {evento.actividad?.materiales?.map((material, indice)=><tr>
                            <td>{material.nombre}</td>
                            <td>{material.cantidad}</td>
                            {user.email === "criztian.torr3s@gmail.com" ? 
                            <><td>
                                <input type={"number"} min={0} value={material.precio} onChange={ev=>changePrice(ev, indice)} />
                            </td><td>
                                {material.precio ? formatoPesos.format( material.subtotal ) : 0}    
                            </td></>
                            : <td>{material.precio ? material.precio : "N/A"}</td>}
                        </tr>)}
                    </tbody>
                </table>
            </div>
            {user.email === "criztian.torr3s@gmail.com" 
                && <><p>
                    Subtotal: {formatoPesos.format(obtenSubtotal())}
                </p>
                <button className="btn btn-primary mt-4" onClick={enviarPrecios}>Enviar precios</button></>}
        </div>
    </div>
}