import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Breadcrumb from "../../panelLayout/Breadcrumb";
import firebase from "firebase"
import { UserContext } from "../../Context/UserContext";
import { LayoutContext } from "../../Context/LayoutContext";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
require("firebase/database")

export default function NuevoAlumno() {
    const { addToast } = useToasts();
    let { alumnoID } = useParams();
    const [form, setForm] = useState({})
    const { user } = useContext(UserContext)
    const history = useHistory();
    const {t} = useTranslation()
    const { setTitle } = useContext(LayoutContext)

    const guardarGrupo = (ev) => {
        ev.preventDefault();
        console.log("el evento", form, user)
        const ref = firebase.database().ref("alumnos").child(user.user_id)
        if(alumnoID){
            ref.child(alumnoID).update(form).then(redirectTo)
        } else {
            ref.push(form).then(redirectTo)
        }
        
        return false
    }
    const redirectTo = () => {
        addToast(t("students.nuevo.message"), { appearance: 'success', autoDismiss: true });
        history.push("/panel/students/list")
    }
    useEffect(()=>{
        console.log("checo el params", alumnoID)
        if(alumnoID && user && user.user_id){
            firebase.database().ref("alumnos").child(user.user_id).child(alumnoID).once("value", snap=>{
                setForm(snap.val())
            })
        }
    }, [alumnoID, user])
    useEffect(()=>setTitle(t("students.nuevo.titulo")), [])

    return <div className="content-body">
        <div className="container-fluid">
            <Breadcrumb pageTitles={[{label: t("migajas.inicio"), to: "/"}, {label: t("students.nuevo.bread"), to: "/panel/students/list"}, t("students.nuevo.titulo")]} />
            
            <div className="row">
                <div className="col-12">
                    <div className="table-responsive table-hover fs-14">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">{t("students.nuevo.titulo")}</h4>
                            </div>
                            <div class="card-body">
                                <div class="basic-form">
                                    <form onSubmit={guardarGrupo}>
                                        <div class="mb-3">
                                            <label class="form-label">{t("students.nuevo.nombre")}: <span className="text-danger">(required)</span></label>
                                            <input type="text" autoFocus value={form.nombre} onChange={ev=>setForm(form=>({...form, nombre: ev.target.value}))} required 
                                                class="form-control input-default " name="nombre"  />
                                        </div>
                                        <div class="mb-3">
                                            <label class="form-label">{t("students.nuevo.padre")}:</label>
                                            <input type="text" value={form.padre} onChange={ev=>setForm(form=>({...form, padre: ev.target.value}))} 
                                                class="form-control input-default " name="padre"  />
                                        </div>
                                        <div class="mb-3">
                                            <label class="form-label">{t("students.nuevo.correo")}: <span className="text-danger">(required)</span></label>
                                            <input type="email" value={form.correo} onChange={ev=>setForm(form=>({...form, correo: ev.target.value}))} required 
                                                class="form-control input-default " name="correo"  />
                                        </div>
                                        <div class="mb-3">
                                            <label class="form-label">{t("students.nuevo.telefono")}:</label>
                                            <input type="phone" value={form.telefono} onChange={ev=>setForm(form=>({...form, telefono: ev.target.value}))} 
                                                class="form-control input-default " name="telefono"  />
                                        </div>
                                        <div className="d-flex flex-row-reverse">
                                            <button type="submit" class="btn btn-primary mt-4">{t("students.nuevo.boton")}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
    </div>
}