import PanelLayout from "../../panelLayout/Layout";
import Breadcrumb from "../../panelLayout/Breadcrumb";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../Context/UserContext";
import firebase from "firebase"
import { LayoutContext } from "../../Context/LayoutContext";
require("firebase/database")

export default function Profesores(params) {
    const { user } = useContext(UserContext)
    const [data, setData] = useState([])
    const { setTitle } = useContext(LayoutContext)

    setTitle("My assigned teachers")

    useEffect(()=>{
        console.log("buscio del emnail", user.email)
        if(!user.email) return false
        firebase.database().ref("datos-alumnos").child("profesores").orderByChild("correoAlumno").equalTo(user.email).on("value", async snap=>{
            const profesoresAux = snap.val()
            console.log("LOS PROFES", profesoresAux)
            if(!profesoresAux) return false
            //si existen, sacamos sus datos
            
            const profesores = []
            const profesSinData = Object.values(profesoresAux)
            for (const profe of profesSinData) {
                const {profesor, correoAlumno} = profe
                const profesorData = await (await firebase.database().ref("users").child(profesor).once("value")).val()
                console.log("la data del profe", profesor, correoAlumno, profesorData)
                profesores.push({...profe, ...profesorData})
            }
            console.log("los profesores son", profesores)
            if(profesores) setData(profesores)
            else setData([])                        
        })
    }, [user])
    return <PanelLayout title="My assigned teachers">
        <div className="content-body">
            <div class="container-fluid">
                <Breadcrumb pageTitles={["Home", "My assigned teachers"]} />
                <div class="row">
                    { data && Array.isArray(data) && data.length > 0 && data.map(profesor=><div class="col-lg-6 col-xl-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row m-b-30">
                                    <div class="col-md-5">
                                        <div class="new-arrival-product mb-4 mb-xxl-4 mb-md-0 h-100">
                                            <div class="new-arrivals-img-contnent h-100">
                                                <img class="img-fluid" src="/images/teacher.png" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="new-arrival-content position-relative">
                                            <h4>{profesor.name}</h4>
                                            <p class="text-content mt-4">{profesor.email}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)}
                </div>
            </div>
        </div>
    </PanelLayout>
}