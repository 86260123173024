import { Route, BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import PanelRouter from "./PanelRouter";
import UnSigned from "./UnSigned";

export default function MainRouter() {
    return <Router>
        <Switch>
            <Route path="/usg">
                <UnSigned/>
            </Route>
            <Route path="/panel">
                <PanelRouter/>
            </Route>
            <Route exact path="/">
                <Redirect to={"/panel"}/>
            </Route>
        </Switch>
    </Router>
}