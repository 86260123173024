import { MenuItem, Select } from "@mui/material"
import React, { useContext } from "react"
//import { LayoutContext } from "../../Context/LayoutContext"
import Loading from "../../panelLayout/Loading/Loading"
//import i18n from "../../Utilities/Multilenguaje"
import "./ContainerForm.css"

export default function ContainerForm({ children }) {
    //const { loadingVisible } = useContext(LayoutContext)
    //console.log("el i10", i18n.language)
    return (
        <div className="authincation h-100">
            <Loading visible={false} />
            <div className="container h-100">
                {/* <div className="row justify-content-end">
                    <Select value={i18n.language} onChange={e => { i18n.changeLanguage(e.target.value) }}>
                        <MenuItem value={"en"}>ENGLISH</MenuItem>
                        <MenuItem value={"es"}>ESPAÑOL</MenuItem>
                    </Select>
                </div> */}
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-md-6">
                        <div className="authincation-content">
                            <div className="row no-gutters">
                                <div className="col-xl-12">
                                    <div className="auth-form">{children}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
