import Breadcrumb from "../../../panelLayout/Breadcrumb";
import PanelLayout from "../../../panelLayout/Layout";
import { useContext, useEffect, useState } from "react";
import { LayoutContext } from "../../../Context/LayoutContext";
import { useParams } from "react-router-dom";
import ModalPreguntas from "./modal";
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import { useToasts } from "react-toast-notifications";
import Constants from "../../../Utilities/Constants";

export default function ExamenesPreguntas() {
    let { examID } = useParams();
    const { setTitle } = useContext(LayoutContext);
    const [open, setOpen] = useState(false);
    const [examen, setExamen] = useState({});
    const [preguntas, setPreguntas] = useState([]);
    const { addToast } = useToasts();

    const obtenerDataExamenes = async () => {
        try {
            const response = await axios.get(Constants.restURL()+`?seccion=obtenerDatosExamen&idExamen=${examID}`);
            console.log("data examen", response.data);
            setExamen(response.data.examen);
            setPreguntas(response.data.preguntas);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const eliminarPregunta = async (ev, preguntaID) => {
        if (window.confirm("Are you sure you want to delete this question?")) {
            const response = await axios.post(Constants.restURL()+"?seccion=eliminarPregunta", {
                idExamen: preguntaID
            });
            console.log("la reso", response)
            // Cierra el modal
            if (response.data.exito === 1) {
                addToast("The question has been deleted successfully", { appearance: 'success', autoDismiss: true });
                obtenerDataExamenes()
            }
        }
    }

    const toggleExamStatus = async (ev) => {
        if(! (preguntas.length > 0)){
            addToast("You can't activate the exam until you've added one or more questions.", { appearance: 'error', autoDismiss: true });
            return false
        }
        const activar = examen.estado === "1"
        if (window.confirm("Are you sure you want to "+(!activar ? "activate": "deactivate")+" this exam?")) {
            const response = await axios.post(Constants.restURL()+"?seccion=cambiarEstadoExamen", {
                examenID: examen.id,
                nuevoEstado: activar ? 0 : 1
            });
            console.log("la reso", response)
            // Cierra el modal
            if (response.data.exito === 1) {
                addToast("The exam has been "+(!activar ? "activated": "deactivated")+" successfully", { appearance: 'success', autoDismiss: true });
                obtenerDataExamenes()
            }
        }
    }

    useEffect(() => {
        setTitle("Edit Exam");
        obtenerDataExamenes();
    }, []);

    return (
        <PanelLayout title={`Edit Exam - ${examen.nombre}`}>
            <div className="content-body">
                <div className="container-fluid">
                    <Breadcrumb pageTitles={["Home", "Edit Exam"]} />
                    <div className="card">
                        <div class="card-header d-sm-flex d-block pb-0 border-0">
                            <div>
                                <h4 class="text-black fs-20">General Data</h4>
                            </div>
                        </div>
                        <div className="card-body">
                            <div class="row">
                                <div class="col-sm-5 mb-4">
                                    <p class="mb-2">Exam Name</p>
                                    <h4 class="text-black">{examen.nombre}</h4>
                                </div>
                                <div class="col-sm-7 mb-4">
                                    <p class="mb-2">Exam Status</p>
                                    <h4 class="text-black">{examen.estado === "1" ? "Active": "Inactive"}</h4>
                                </div>
                                <div class="col-sm-7">
                                    <button class="btn btn-sm btn-outline-primary rounded-0 mr-2" onClick={() => setOpen(true)}>Add a new Question</button>
                                    {   examen.estado === "1" ?
                                        <button class="btn btn-sm btn-outline-danger rounded-0" onClick={toggleExamStatus}>Deactivate</button>:
                                        <button class="btn btn-sm btn-outline-success rounded-0" onClick={toggleExamStatus}>Activate</button> 
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div class="card-header d-sm-flex d-block pb-0 border-0">
                            <div>
                                <h4 class="text-black fs-20">Questions and answers</h4>
                            </div>
                        </div>
                        <div className="card-body">
                            <table class="table table-stripped table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Questions</th>
                                        <th>Responses</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {preguntas.map((pregunta, index) => (
                                        <tr key={pregunta.id}>
                                            <td>{index + 1}</td>
                                            <td>{pregunta.pregunta.replace(/<[^>]*>/g, "")}</td>
                                            <td>
                                                {pregunta.respuestas.map((respuesta) => (
                                                    <div key={respuesta.id}>
                                                        {respuesta.respuesta+" "}
                                                        {respuesta.valor === "1" ? <span className="text-success">[correcta]</span> : null}
                                                    </div>
                                                ))}
                                            </td>
                                            <td>
                                                <button onClick={ev=>eliminarPregunta(ev, pregunta.id)}><DeleteIcon color="white"/></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <ModalPreguntas open={open} setOpen={setOpen} idExamen={examID} obtenerDataExamenes={obtenerDataExamenes}/>
        </PanelLayout >
    );
}
