import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import firebase from "firebase"
import { useTranslation } from "react-i18next";
import { UserContext } from "./UserContext";
require("firebase/database")

const ClassContext = React.createContext()
const { Provider, Consumer } = ClassContext

const ClassProvider = ({ children, setLoading, classID }) => {
    const history = useHistory();
    const { user } = useContext(UserContext)
    const { addToast } = useToasts();
    const {t} = useTranslation()

    const [form, setForm] = useState({
        recordatorios: {}, 
        mandarRecordatorios: false, 
        repetir : {},
        recurrente: false,
        repetirCada: '',
        tipoRepetir: 'semana',
        dias: [],
        termina: 'nunca',
        fechaFinal: '',
        repeticiones: ''
    })
    const [step, setStep] = useState(0)
    const [IDFirebase, setIDFirebase] = useState()

    const borrarMaterial = (index) => {
        let materials = form.materiales
        materials.splice(index, 1)
        setForm(form=>({...form, materiales: materials }))
    }
    const editarMaterial = (index, char, value) => {
        let formAux = form
        formAux.materiales[index][char] = value
        setForm(formAux)
    }

    const deleteLink = (index) => {
        let links = form.links
        links.splice(index, 1)
        setForm(form=>({...form, links: links }))
    }
    const editLink = (index, char, value) => {
        let formAux = form
        formAux.links[index][char] = value
        setForm(formAux)
    }
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );

    const saveClass = (ev) => {
        ev.preventDefault();
        setLoading(true)
        console.log("el evento", form)
        let auxAccount = {
            ...form, 
            accountID: user.user_id, 
            estado: "Activa", 
            timezoneOffset: (new Date()).getTimezoneOffset(), 
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        firebase.database().ref("clases").push(auxAccount).then(()=>{
            delay(10000).then(()=>{
                setLoading(false)
                addToast(t("clase.nueva.mensaje"), { appearance: 'success', autoDismiss: true });
                history.push("/panel/schedule")
            })
            
        })
        return false
    }

    const editClass = (ev) => {
        ev.preventDefault();
        setLoading(true)
        //console.log("el evento", form)
        firebase.database().ref("clases").child(IDFirebase).set(form).then(()=>{
            delay(10000).then(()=>{
                setLoading(false)
                addToast("The Class has been edited successfully", { appearance: 'success', autoDismiss: true });
                history.push("/panel/schedule")
            })
            
        })
        return false
    }

    const formSubmit = (ev) => classID ? editClass(ev) : saveClass(ev)

    useEffect(()=>{
        if(!classID) return false
        firebase.database().ref("clases").orderByChild("googleID").equalTo(classID).once("value", async (snap)=>{
            const value = snap.val()
            console.log("el val", value)
            let data = Object.values(value)[0]
            setIDFirebase(Object.keys(value)[0])
            if(!data.recordatorios) data.recordatorios = {}
            setForm(data)
        });
    }, [classID])

    useEffect(()=>{
        console.log("el recurrente")
        if(form.recurrente) setForm(form=>({...form, mismoHorario: true }))
        else setForm(form=>({...form, mismoHorario: false }))
    }, [form.recurrente])

    return <Provider value={{ 
        form, setForm, step, setStep, borrarMaterial, editarMaterial, formSubmit, deleteLink,
        editLink
    }}>
        {children}
    </Provider>
}

export { ClassProvider, Consumer, ClassContext }
