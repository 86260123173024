import PanelLayout from "../../panelLayout/Layout";
import Breadcrumb from "../../panelLayout/Breadcrumb";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../Context/UserContext";
import firebase from "firebase"
import { LayoutContext } from "../../Context/LayoutContext";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Link } from "react-router-dom";
require("firebase/database")

export default function GruposAlumnos() {
    const { user } = useContext(UserContext)
    const [data, setData] = useState([])
    const { setTitle } = useContext(LayoutContext)

    setTitle("My Classrooms")

    useEffect(()=>{
        console.log("buscio del emnail", user.email)
        if(!user.email) return false
        firebase.database().ref("datos-alumnos").child("grupos").orderByChild("correoAlumno").equalTo(user.email).on("value", async snap=>{
            const gruposAux = snap.val()
            if(!gruposAux) return false
            //si existen, sacamos sus datos
            const grupos = []
            const profesSinData = Object.values(gruposAux)
            for (const grupoActual of profesSinData) {
                const {grupo, correoAlumno, accountID} = grupoActual
                const grupoData = await (await firebase.database().ref("grupos").child(accountID).child(grupo).once("value")).val()
                console.log("la data del profe", grupoActual, correoAlumno, grupoData)
                grupos.push({...grupoActual, ...grupoData})
            }
            console.log("los grupos son", grupos)
            if(grupos) setData(grupos)
            else setData([])                        
        })
    }, [user])
    return <PanelLayout title="My Classrooms">
        <div className="content-body">
            <div class="container-fluid">
                <Breadcrumb pageTitles={["Home", "My Classrooms"]} />
                {data.length > 0 ? <div class="row">
                    { data && Array.isArray(data) && data.length > 0 && data.map(profesor=><Link class="col-lg-6 col-xl-4" to={`/panel/students/groups/details/${profesor.accountID}/${profesor.grupo}`}>
                        <div class="card">
                            <div class="card-body">
                                <div class="row m-b-30">
                                    <div class="col-md-12">
                                        <div class="new-arrival-content position-relative">
                                            <h4>Classroom: {profesor.nombre}</h4>
                                            <p class="text-content mt-4">School: {profesor.escuela ? profesor.escuela : "N/A"}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>)}
                </div> :
                <div className="d-flex align-items-center flex-column mt-4">
                    <h3>There are no registered Classrooms in your account.</h3>
                    <PlaylistAddIcon style={{ fontSize: 80, color: "rgb(182 136 243)" }} color="#ebdffb" />
                </div>}
            </div>
        </div>
    </PanelLayout>
}