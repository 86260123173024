import { useContext, useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Breadcrumb from "../../panelLayout/Breadcrumb";
import firebase from "firebase"
import { UserContext } from "../../Context/UserContext";

import { useTranslation } from "react-i18next";
import { LayoutContext } from "../../Context/LayoutContext";
import GroupsList from "./GroupsListComp/GroupsList";
import EmptyGroupsList from "./GroupsListComp/EmptyGroupsList";
require("firebase/database")

export default function Grupos() {
    const history = useHistory();
    const {t} = useTranslation()
    const [data, setData] = useState({})
    const { user } = useContext(UserContext)
    const { setTitle } = useContext(LayoutContext)
    
    useEffect(()=>{
        if(!user.user_id) return false
        firebase.database().ref("grupos").child(user.user_id).on("value", snap=>{
            const grupos = snap.val()
            console.log("los grupos son", grupos)
            if(grupos) setData(grupos)
            else setData({})                        
        })
    }, [user])
    useEffect(()=>setTitle(t("grupos.lista.titulo")), [])

    return <div className="content-body">
        <div className="container-fluid">
            <Breadcrumb pageTitles={[{label: t("migajas.inicio"), to: "/"}, t("grupos.lista.titulo")]} />
            
            <div className="row">
                <div className="col-12">
                    <div class="form-head d-flex mb-3 mb-md-5 align-items-center  flex-row-reverse">
                        <NavLink class="ms-auto rounded-0 btn bgl-primary text-primary" to="/panel/groups/new" component="a">
                            {t("grupos.lista.boton")}
                        </NavLink>
                    </div>
                    { Object.keys(data).length === 0 ? 
                        EmptyGroupsList() : 
                        GroupsList(t, data, history)}
                </div>
            </div>
        </div>    
    </div>
}


