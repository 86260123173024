import React, { useContext, useEffect } from "react"
import { UserContext } from "../Context/UserContext"
import Iframe from 'react-iframe'
import { useTranslation } from "react-i18next"
import { LayoutContext } from "../Context/LayoutContext"

export default function PanelLayout() {
    const {t} = useTranslation()
    const { setTitle } = useContext(LayoutContext)  
    const { user } = useContext(UserContext)
    console.log("le user", user)
    //los que entras con gmail, estandar : "https://www.ililililili.com/skoolar"
    let iframeURL = "https://www.ililililili.com/skoolar"
    // if(user && user.email && user.email.includes("@skoolar.app")){
    //     iframeURL = "https://www.ililililili.com/skoolar-skoolar-home"
    // }
    // if(user && user.email && user.email.includes("@mimbokids.com")){
    //     iframeURL = "https://www.ililililili.com/skoolar-minbo-home"
    // } 
    // if(user && user.email && user.email === "getyourmediaright@gmail.com"){
    //     iframeURL = "https://www.ililililili.com/skoolar-t-10001"
    // }
    //custom teacher landing 

    useEffect(()=>setTitle(t("migajas.inicio")), [])
    return <div className="content-body">
        <div className="container-fluid">
            <Iframe url={iframeURL}
            width="100%"
            height="2000px"
            id=""
            className=""
            frameBorder="0"
            display="block"
            position="relative"/>
        </div>
    </div>
}