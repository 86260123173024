import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import Breadcrumb from "../../../panelLayout/Breadcrumb";
import PanelLayout from "../../../panelLayout/Layout";
import { LayoutContext } from "../../../Context/LayoutContext";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Step, StepLabel, Stepper } from '@mui/material';
import { UserContext } from '../../../Context/UserContext';
import { useToasts } from 'react-toast-notifications';
import Constants from '../../../Utilities/Constants';

export default function ExamenesAplicar() {
    const { setTitle } = useContext(LayoutContext);
    const { user } = useContext(UserContext)
    const { addToast } = useToasts();
    const history = useHistory();
    const { examID } = useParams();
    const [preguntas, setPreguntas] = useState([]);
    const [step, setStep] = useState(0);
    const [respuestasAlumno, setRespuestasAlumno] = useState({});

    useEffect(() => {
        setTitle("My exams");
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(Constants.restURL()+"?seccion=obtenerPreguntasConRespuestas&examenID=" + examID);
            console.log("las preg y resp", response.data)
            setPreguntas(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleNextQuestion = () => {
        if (step < preguntas.length - 1) {
            setStep(step + 1);
        }
    };

    const handlePreviousQuestion = () => {
        if (step > 0) {
            setStep(step - 1);
        }
    };

    const rateTest = async (ev) =>{
        ev.preventDefault()
        let respArray = []
        Object.keys(respuestasAlumno).forEach((indxx)=>{
            respArray.push({
                preguntaID: indxx,
                respuestaID:  respuestasAlumno[indxx]
            })
        })
        const payload = {
            respuestas: respArray, userID: user.user_id, userEmail: user.email, examID
        }
        
        try {
            const response = await axios.post(Constants.restURL()+"?seccion=calificarExamen", payload);
            if (response.data.exito === 1) { //idAplicacion
                addToast("The exam has been processed successfully.", { appearance: 'success', autoDismiss: true });
                history.push("/panel/exams/score/"+examID+"/"+response.data.idAplicacion)
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    function createMarkup(html) {
        return {__html: html};
      }
    return (
        <PanelLayout title="My exams">
            <div className="content-body">
                <div className="container-fluid">
                    <Breadcrumb pageTitles={["Home", "My exams"]} />
                    <div className="row">
                        <div className="col-md-12">
                            <div className="container">
                                {preguntas.length > 0 && <div className="card-body">
                                    <Stepper activeStep={step} alternativeLabel style={{ marginBottom: 40 }}>
                                        {preguntas.map((pregunta, index) => (
                                            <Step key={index}>
                                                <StepLabel>{`Question ${index + 1}`}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                    <div className="basic-form mt-4">
                                        <div  className="mt-4">
                                            <div className="card">
                                                <div className="card-header d-sm-flex d-block pb-0 border-0">
                                                    {/* <h3 className='text-capitalize'>{preguntas[step].pregunta}</h3> */}
                                                    <div dangerouslySetInnerHTML={createMarkup(preguntas[step].pregunta)} />
                                                </div>
                                                <div className="card-body">
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">Select one option</FormLabel>
                                                        <RadioGroup aria-label="pregunta" name="pregunta" value={respuestasAlumno[preguntas[step].preguntaID] || false} // Obtener la respuesta seleccionada del alumno
                                                            onChange={(event) => setRespuestasAlumno(prev=> ({...prev, [preguntas[step].preguntaID]: event.target.value}))}>
                                                            {preguntas[step].respuestas.map((respuesta, index) => (
                                                                <FormControlLabel key={index} value={respuesta.id} control={<Radio />} label={respuesta.respuesta} />
                                                            ))}
                                                        </RadioGroup>
                                                    </FormControl>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-row-reverse mt-4">
                                                {step === (preguntas.length - 1) ?
                                                    <button type="button" onClick={rateTest} className="btn btn-primary ml-3 mt-4">Submit</button>
                                                    :
                                                    <button type="button" onClick={handleNextQuestion} className="btn btn-info ml-3 mt-4">Next</button>
                                                }
                                                {step > 0 &&
                                                    <button type="button" onClick={handlePreviousQuestion} className="btn btn-info ml-3 mt-4">Previous</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PanelLayout>
    );
}