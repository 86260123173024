import React, { useEffect, useRef, useState } from "react"

const LayoutContext = React.createContext()
const { Provider, Consumer } = LayoutContext

const LayoutProvider = ({ children }) => {
	const [loadingVisible, setLoadingVisible] = useState(false)
	const [title, setTitle] = useState("")

	const [toggledMenu, setToggledMenu] = useState(false)
	const buttonRef = useRef(null);
	const menuRef = useRef(null);
	const handleOutsideClick = (event) => {
		const button = buttonRef.current;
		const menu = menuRef.current;

		if (button && menu && !button.contains(event.target) && !menu.contains(event.target)) {
			// Si se hace clic fuera del botón y del menú, cierra el menú
			setToggledMenu(false);
		} else if (event.target.className === "nav-text") {
			setToggledMenu(false);
		}
	};

	const openSidebar = () => {
		setToggledMenu(true);
		console.log("le ponemos listener")
		document.addEventListener('click', handleOutsideClick);

		//   document.removeEventListener('click', handleOutsideClick);
		// }
	};

	useEffect(() => {
		let style = document.body.getAttribute('data-sidebar-style')
		if (style === "overlay" && !toggledMenu) {
			console.log("lo removemos")
			document.removeEventListener('click', handleOutsideClick);
		}
	}, [toggledMenu])

	return <Provider value={{
		loadingVisible, setLoadingVisible, toggledMenu,
		setToggledMenu, title, setTitle, openSidebar, buttonRef,
		menuRef
	}}>{children}</Provider>
}

export { LayoutProvider, Consumer as LayoutConsumer, LayoutContext }
