import { useTranslation } from "react-i18next";
import { ClassContext } from "../../../Context/ClassContext";
import { useContext } from "react";

export default function ClassDetailsComp () {
    const { form, setForm, editarMaterial, borrarMaterial, deleteLink, editLink } = useContext(ClassContext)
    const {t} = useTranslation()
    return <>
        <div class="mb-3">
            <label class="form-label">{t("clase.nueva.nombre")} <span className="text-danger">(required)</span>:</label>
            <input type="text" autoFocus key={"name"} value={form.nombre} onChange={ev=>setForm(form=>({...form, nombre: ev.target.value}))} required 
                class="form-control input-default " name="nombre"  />
        </div>
        <div class="mb-3">
            <label class="form-label">{t("clase.nueva.descripcion")}:</label>
            <textarea type="text" value={form.descripcion} rows={4} onChange={ev=>setForm(form=>({...form, descripcion: ev.target.value}))}  
                class="form-control input-default " name="descripcion"  />
        </div>
        <div class="mb-3">
            <label class="form-label">Location:</label>
            <select 
                value={form.lugar}
                onChange={ev => setForm(form => ({...form, lugar: ev.target.value}))}
                className="form-control input-default"
                name="lugar"
            >
                <option value="">-- Select an option --</option>
                <option value="onsite">On-site</option>
                <option value="online">Online</option>
            </select>
        </div>
        <div class="mb-3">
            <label class="form-label">Notes:</label>
            <textarea type="text" value={form.notas} rows={4} onChange={ev=>setForm(form=>({...form, notas: ev.target.value}))}  
                class="form-control input-default " name="notas"  />
        </div>
        {form.links && Array.isArray(form.links) && form.links.length > 0 && <div className="table-responsive table-hover fs-14">
            <table className="table display mb-4 dataTablesCard  card-table table-stripped">
                <thead>
                    <tr>
                        <th>Label</th>
                        <th>Url</th>
                        <th>{t("clase.nueva.tabla.acciones")}</th>
                    </tr>
                </thead>
                <tbody> 
                    { form.links.map((material, index)=>{
                            return <tr>
                                <td>
                                    <input type="text" value={material.label} onChange={ev=>editLink(index, "label", ev.target.value)} required 
                                        class="form-control input-default " name="nombreMaterial" placeholder="Ex: whitepaper" />
                                </td>
                                <td>
                                    <input type="url" value={material.url} onChange={ev=>editLink(index, "url", ev.target.value)} required 
                                        class="form-control input-default " name="cantidadMaterial" min={0} placeholder="Ex: https://google.com"/>    
                                </td>
                                <td>
                                    <div class="d-flex">
                                        <button type="button" onClick={()=>deleteLink(index)} class="btn btn-danger ml-2 shadow btn-xs sharp"><i class="fa fa-trash"></i></button>
                                    </div>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>}
        {form.materiales && Array.isArray(form.materiales) && form.materiales.length > 0 && <div className="table-responsive table-hover fs-14">
            <table className="table display mb-4 dataTablesCard  card-table table-stripped">
                <thead>
                    <tr>
                        <th>{t("clase.nueva.tabla.material")}</th>
                        <th>{t("clase.nueva.tabla.cantidad")}</th>
                        <th>{t("clase.nueva.tabla.acciones")}</th>
                    </tr>
                </thead>
                <tbody> 
                    { form.materiales.map((material, index)=>{
                            return <tr>
                                <td>
                                    <input type="text" value={material.nombre} onChange={ev=>editarMaterial(index, "nombre", ev.target.value)} required 
                                        class="form-control input-default " name="nombreMaterial" placeholder={t("clase.nueva.tabla.placeMaterial")} />
                                </td>
                                <td>
                                    <input type="number" value={material.cantidad} onChange={ev=>editarMaterial(index, "cantidad", ev.target.value)} required 
                                        class="form-control input-default " name="cantidadMaterial" min={0} placeholder={t("clase.nueva.tabla.placeCant")} />    
                                </td>
                                <td>
                                    <div class="d-flex">
                                        <button type="button" onClick={()=>borrarMaterial(index)} class="btn btn-danger ml-2 shadow btn-xs sharp"><i class="fa fa-trash"></i></button>
                                    </div>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>}
    </>
}