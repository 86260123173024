import { NavLink } from "react-router-dom";
import Breadcrumb from "../../../panelLayout/Breadcrumb";
import PanelLayout from "../../../panelLayout/Layout";
import { useContext, useEffect, useState } from "react";
import { LayoutContext } from "../../../Context/LayoutContext";
import axios from "axios";
import { UserContext } from "../../../Context/UserContext";
import Constants from "../../../Utilities/Constants";
import RecommendIcon from '@mui/icons-material/Recommend';

export default function ExamenesMios() {
    const { setTitle } = useContext(LayoutContext);
    const { user } = useContext(UserContext)

    useEffect(() => {
        setTitle("My exams");
        fetchData();
    }, []);

    const [examenes, setExamenes] = useState([]);

    useEffect(() => {
        fetchData();
        // const intervalId = setInterval(reloj, 1000);
        // return () => clearInterval(intervalId);
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(Constants.restURL()+"?seccion=examenesMios&student="+user.email);
            console.log("la data", response.data)
            if(response.data){
                setExamenes(response.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    return (
        <PanelLayout title="My exams">
            <div className="content-body">
                <div className="container-fluid">
                    <Breadcrumb pageTitles={["Home", "My exams"]} />
                    {examenes.length > 0 ? <div className="row">
                        {examenes.map((examen, index) => (
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-header border-0 pb-0">
                                        <h2 className="card-title">{examen.nombre}</h2>
                                    </div>
                                    <div className="card-body pb-0">
                                        <ul className="list-group list-group-flush">
                                            {/* <li className="list-group-item d-flex px-0 justify-content-between">
                                                <strong>Course</strong>
                                                <span className="mb-0">{examen.materia_nombre}</span>
                                            </li> */}
                                            <li className="list-group-item d-flex px-0 justify-content-between">
                                                <strong>Done</strong>
                                                <span className="mb-0">{examen.aplicaciones_id ? "YES" : "NO"}</span>
                                            </li>
                                            <li className="list-group-item d-flex px-0 justify-content-between">
                                                <strong>Application Date</strong>
                                                <span className="mb-0">{examen.aplicaciones_creacion ?? "N/A"}</span>
                                            </li>
                                            <li className="list-group-item d-flex px-0 justify-content-between">
                                                <strong>Exam Passed</strong>
                                                {examen.aplicaciones_id ? <span style={{color: (examen.aplicaciones_aprobado === "1" ? "blue": "red")}} className="mb-0">{examen.aplicaciones_aprobado === "1" ? "YES" : "NO"}</span> : "N/A"}
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="card-footer  text-center">
                                        {examen.aplicaciones_id &&<NavLink className="btn btn-outline-info pull-right" to={"/panel/exams/score/"+examen.id+"/"+examen.aplicaciones_id}>Score Details</NavLink>}
                                        {!examen.aplicaciones_id &&<NavLink className="btn btn-primary pull-right" to={"/panel/exams/apply/"+examen.id}>Apply Exam</NavLink>}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>: <div className="d-flex align-items-center flex-column mt-4">
                        <h3>At the moment, you don't have any pending exams to apply.</h3>
                        <RecommendIcon style={{ fontSize: 80, color: "rgb(182 136 243)" }} color="#ebdffb" />
                    </div>}
                </div>
            </div>
        </PanelLayout>
        
    );
}
